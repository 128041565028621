export const TEST_MODELS = [
  {
    id: '101',
    name: 'st_comb/st_1',
    file: 'st_1.splat',
    viewSettings: {
      camera: {
        position: [-22.08, 9.11, -7.05],
        rotation: [-3.07, -50.37, -5.01],
        fov: 75,
        near: 0.1,
        far: 1000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 200,
      maxPanY: 200,
      maxPanZ: 200,
    },
  },
  {
    id: '102',
    name: 'st_comb/st_2',
    file: 'st_2.splat',
    viewSettings: {
      camera: {
        position: [-22.08, 9.11, -7.05],
        rotation: [-3.07, -50.37, -5.01],
        fov: 75,
        near: 0.05,
        far: 1000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 200,
      maxPanY: 200,
      maxPanZ: 200,
    },
  },
  { id: '103', name: 'RCH', file: 'rch.splat' },
  {
    id: '104',
    name: 'st_comb/st_1a',
    file: 'ST_1_antialiased.splat',
    viewSettings: {
      camera: {
        position: [-24.24, -8.12, -7.82],
        rotation: [-92.05, -47.13, -4.72],
        fov: 75,
        near: 0.1,
        far: 1000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 200,
      maxPanY: 200,
      maxPanZ: 200,
    },
  },
  {
    id: '105',
    name: 'st_comb/st_2a',
    file: 'ST_2_antialiased.splat',
    viewSettings: {
      camera: {
        position: [-24.24, -8.12, -7.82],
        rotation: [-92.05, -47.13, -4.72],
        fov: 75,
        near: 0.05,
        far: 1000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 200,
      maxPanY: 200,
      maxPanZ: 200,
    },
  },
  {
    id: '106',
    name: 'tower/0607',
    file: 'point_cloud_0607.splat',
    viewSettings: {
      camera: {
        position: [0, 0, 162],
        rotation: [0, 0, 0],
        fov: 75,
        near: 0.1,
        far: 10000000,
      },
    },
    cameraData: [
      {
        id: 257,
        position: [
          -1.0744728129533727, -0.18783209635097783, -1.9615802458554707,
        ],
        rotation: [
          0.014323226568438228, 0.2431066693710997, 0.11129853801078438,
        ],
      },
      {
        id: 256,
        position: [
          -0.5803247963693393, -0.08722642969151423, -2.137381015979411,
        ],
        rotation: [
          0.02807933586429483, 0.019660057121869245, -0.012333203813573373,
        ],
      },
      {
        id: 255,
        position: [
          -0.06698974416393544, -0.04387465077205209, -2.1792081388940336,
        ],
        rotation: [
          0.014195082409056432, -0.204712614961299, -0.13639423632988337,
        ],
      },
      {
        id: 254,
        position: [
          0.4281529271183423, -0.05589370869765742, -2.139231133550761,
        ],
        rotation: [
          -0.02930128661383205, -0.4240473592306164, -0.27258828104490873,
        ],
      },
      {
        id: 253,
        position: [
          0.9001340477717075, -0.1301318033011604, -1.9741692774128112,
        ],
        rotation: [
          -0.11628489538697856, -0.6367892121004689, -0.44114441577278046,
        ],
      },
      {
        id: 252,
        position: [
          1.3140649104582196, -0.2508800265602704, -1.7135574523247188,
        ],
        rotation: [
          -0.2763747190333789, -0.8342564054883027, -0.676379484488387,
        ],
      },
      {
        id: 251,
        position: [
          1.6399858256226711, -0.4132522831887571, -1.3414233190240112,
        ],
        rotation: [
          -0.5573470188257946, -0.9904311001100403, -1.027762615284976,
        ],
      },
      {
        id: 250,
        position: [
          1.8420809611759974, -0.6132986988194351, -0.9424368971164212,
        ],
        rotation: [
          -0.9741232871506427, -1.0615930492095114, -1.5114443879367065,
        ],
      },
      {
        id: 249,
        position: [
          1.9340927334428106, -0.8374901891682289, -0.47143551584613363,
        ],
        rotation: [-1.4191520828199222, -1.019119596291305, -2.023710196869362],
      },
      {
        id: 248,
        position: [
          1.8759784576639889, -1.0460357940326293, -0.018606345400711155,
        ],
        rotation: [
          -1.7353745890767964, -0.8845477431288842, -2.407908953740967,
        ],
      },
      {
        id: 247,
        position: [1.7139383819755432, -1.2579546405171846, 0.422462758721212],
        rotation: [
          -1.926928005747266, -0.6925332325980875, -2.6753355848040927,
        ],
      },
      {
        id: 246,
        position: [1.3831622093848481, -1.4183280849719107, 0.7890946510389885],
        rotation: [
          -2.0318601331209556, -0.48242593476064877, -2.8601681112775883,
        ],
      },
      {
        id: 245,
        position: [0.9773209308340294, -1.5481138257439555, 1.0833838281670247],
        rotation: [
          -2.0868012707320487, -0.2593707231463074, -3.0069566830223193,
        ],
      },
      {
        id: 244,
        position: [0.5270160739573517, -1.6173412959562652, 1.2798733478625552],
        rotation: [
          -2.1070991930483984, -0.03370891859903469, -3.1335015584408863,
        ],
      },
      {
        id: 243,
        position: [
          0.026556162576559683, -1.6223722081103655, 1.368154255480647,
        ],
        rotation: [-2.0986517004368253, 0.19394905882430202, 3.024940806486061],
      },
      {
        id: 242,
        position: [
          -0.4566356817014317, -1.5704945454488506, 1.3252916521190863,
        ],
        rotation: [-2.059775697214096, 0.4148832728331535, 2.8896802055985797],
      },
      {
        id: 241,
        position: [
          -0.9271707383118524, -1.4418632082282092, 1.1546081674192683,
        ],
        rotation: [-1.97470295291122, 0.6377897866423674, 2.717284652799386],
      },
      {
        id: 240,
        position: [
          -1.3178165202811731, -1.2792270028327224, 0.9181036023962024,
        ],
        rotation: [-1.8282474560289927, 0.8293340011820756, 2.497357824631882],
      },
      {
        id: 239,
        position: [
          -1.6754859436389027, -1.0745768436543954, 0.6007887651861674,
        ],
        rotation: [-1.5480908758721628, 0.9947067668264937, 2.143758994561401],
      },
      {
        id: 238,
        position: [
          -1.8797638922941458, -0.8353623311998989, 0.2300737565733903,
        ],
        rotation: [-1.1309402437630376, 1.072435701478002, 1.6588982215816719],
      },
      {
        id: 237,
        position: [
          -1.9564527939143086, -0.5649323842348575, -0.1918644333785443,
        ],
        rotation: [-0.6776536296701392, 1.0369400920188245, 1.1384611581362696],
      },
      {
        id: 236,
        position: [
          -1.899756108148389, -0.3076034484175636, -0.6286735023360122,
        ],
        rotation: [
          -0.34080134550457925, 0.9000125836689978, 0.7296222261485159,
        ],
      },
      {
        id: 235,
        position: [
          -1.7103562467873985, -0.05400751942381282, -1.0527601010963943,
        ],
        rotation: [
          -0.14783292411948196, 0.7098232354756271, 0.4602595963815746,
        ],
      },
      {
        id: 234,
        position: [
          -1.3898382955831652, 0.16090385446103558, -1.4093314901861245,
        ],
        rotation: [
          -0.044858158759097905, 0.5012653158884208, 0.2762174199074441,
        ],
      },
      {
        id: 233,
        position: [
          -1.0372935160345973, 0.3370452947907099, -1.6701976302480783,
        ],
        rotation: [
          0.009122209493077092, 0.2780465232337934, 0.12950263809335297,
        ],
      },
      {
        id: 232,
        position: [-0.549891126576644, 0.4508442074006075, -1.844961233912221],
        rotation: [
          0.027799869575538358, 0.04391417828309496, -0.002443454797740059,
        ],
      },
      {
        id: 231,
        position: [
          -0.06170418492798019, 0.5030206084061475, -1.9049915692552846,
        ],
        rotation: [
          0.017745016489683928, -0.1853049237043043, -0.1289427128044889,
        ],
      },
      {
        id: 230,
        position: [
          0.43313984639113623, 0.4887295271978073, -1.8492118531639972,
        ],
        rotation: [
          -0.02326006075573782, -0.4065403543080912, -0.26331021974029145,
        ],
      },
      {
        id: 229,
        position: [0.9154176457427706, 0.4163872343232103, -1.6743405698353462],
        rotation: [
          -0.10872413462972458, -0.6253772105984955, -0.4321617166124902,
        ],
      },
      {
        id: 228,
        position: [1.3286063736528377, 0.2927412493176422, -1.4055649738904468],
        rotation: [
          -0.2641570602025737, -0.8248155396276147, -0.6624040791936144,
        ],
      },
      {
        id: 227,
        position: [1.6580771712345466, 0.11584691922041535, -1.060180093969629],
        rotation: [
          -0.5331679100490305, -0.9838877827894347, -1.0014314809537217,
        ],
      },
      {
        id: 226,
        position: [
          1.8563452693644074, -0.08667339819345177, -0.6282024057595803,
        ],
        rotation: [-0.946859998184318, -1.0622489243618238, -1.48161388623211],
      },
      {
        id: 225,
        position: [1.950075690663626, -0.3043358934704714, -0.1877548830469078],
        rotation: [
          -1.4111751965004957, -1.023066562618006, -2.0158953982433125,
        ],
      },
      {
        id: 224,
        position: [1.891389145976047, -0.5024973687330103, 0.25914840206074036],
        rotation: [
          -1.724272771532637, -0.8948571525012512, -2.3942676397055287,
        ],
      },
      {
        id: 223,
        position: [1.6911873432208573, -0.7084129098065299, 0.7126675440730172],
        rotation: [
          -1.9144274650982052, -0.7133118430916244, -2.6558443159064646,
        ],
      },
      {
        id: 222,
        position: [1.3817788586355915, -0.8975176668014117, 1.0929216151050973],
        rotation: [
          -2.027008013255877, -0.49817579263890077, -2.8490147712547897,
        ],
      },
      {
        id: 221,
        position: [0.9881810813617585, -1.0315607791287755, 1.3942714796702518],
        rotation: [
          -2.0849044556964436, -0.27618420318034276, -2.995949076109302,
        ],
      },
      {
        id: 220,
        position: [0.5306205601745513, -1.093845697859909, 1.5842975164633606],
        rotation: [
          -2.108175768136927, -0.04707236713463003, -3.1256302086835253,
        ],
      },
      {
        id: 219,
        position: [
          0.039199234507778846, -1.092461664820962, 1.6662438403116404,
        ],
        rotation: [-2.1021885135258005, 0.18118558957578368, 3.032900796758992],
      },
      {
        id: 218,
        position: [
          -0.4528355069779182, -1.0463003475816337, 1.6287268592970476,
        ],
        rotation: [-2.0654969237618754, 0.4024512747142097, 2.8993789707622524],
      },
      {
        id: 217,
        position: [
          -0.9291882256095076, -0.9130157791603803, 1.4502249410364687,
        ],
        rotation: [-1.9866550335691273, 0.6236438038908569, 2.7338730471358077],
      },
      {
        id: 216,
        position: [-1.327590056266331, -0.7499817118397801, 1.2233051637821282],
        rotation: [-1.8382918738083296, 0.8260830077950287, 2.508103085087944],
      },
      {
        id: 215,
        position: [
          -1.6426007015668644, -0.5463056515291556, 0.9169035115065043,
        ],
        rotation: [-1.5768094622723967, 0.9886245251071766, 2.1761300360742566],
      },
      {
        id: 214,
        position: [
          -1.8622240675169008, -0.28621511225010277, 0.4886118575158574,
        ],
        rotation: [-1.1610618189490114, 1.0737518163499429, 1.6914238359717437],
      },
      {
        id: 213,
        position: [
          -1.9447142750660609, -0.02586009383494292, 0.07985994957150544,
        ],
        rotation: [-0.6938306283010578, 1.0422672348535311, 1.154603596454911],
      },
      {
        id: 212,
        position: [
          -1.8972900769841856, 0.24627843882506406, -0.3690995084786116,
        ],
        rotation: [-0.350636892896188, 0.9071822714963087, 0.7386190057511608],
      },
      {
        id: 211,
        position: [
          -1.720195682409352, 0.48336392616319557, -0.7484029243721976,
        ],
        rotation: [
          -0.15782136399590518, 0.7237944962514993, 0.4726527385721794,
        ],
      },
      {
        id: 210,
        position: [
          -1.4027961068473105, 0.6942050650148738, -1.0976257758220485,
        ],
        rotation: [
          -0.048244687084297105, 0.5108482289273741, 0.2818070830099575,
        ],
      },
      {
        id: 209,
        position: [-1.001521325170861, 0.8744597337543287, -1.3760154611383084],
        rotation: [
          0.008499411537896809, 0.28729181212299837, 0.1341760193111866,
        ],
      },
      {
        id: 208,
        position: [-0.5212433968810388, 0.983762954906175, -1.5480625727270365],
        rotation: [
          0.028729533370345298, 0.06208634963381998, 0.006504847697275468,
        ],
      },
      {
        id: 207,
        position: [
          -0.044608843325417424, 1.0443838554570264, -1.6182892354705352,
        ],
        rotation: [
          0.020560323060693615, -0.16648830857493535, -0.11865992536886816,
        ],
      },
      {
        id: 206,
        position: [0.45173381381698674, 1.033034368964312, -1.5578213952804454],
        rotation: [
          -0.020768639678837783, -0.39831622248836784, -0.25840884806874875,
        ],
      },
      {
        id: 205,
        position: [0.9176067128515749, 0.9522509520961013, -1.3658767167090353],
        rotation: [
          -0.10348392576884688, -0.6158442861946444, -0.42337463411532994,
        ],
      },
      {
        id: 204,
        position: [1.3172442968635896, 0.8313525704845233, -1.0981454784649278],
        rotation: [
          -0.25289685645915805, -0.814021756856268, -0.6468593191036482,
        ],
      },
      {
        id: 203,
        position: [1.649554560619416, 0.6654777643875687, -0.7636339570452866],
        rotation: [
          -0.5102527869304344, -0.9740877177891286, -0.9734283101798861,
        ],
      },
      {
        id: 202,
        position: [
          1.8686074685800542, 0.4659093367973546, -0.35206806827359893,
        ],
        rotation: [
          -0.9193207292886911, -1.0605369416645076, -1.4513107616003014,
        ],
      },
      {
        id: 201,
        position: [1.940574460134608, 0.2372247078943146, 0.12900950050634716],
        rotation: [
          -1.370302771558709, -1.0321419589706866, -1.9676575893585841,
        ],
      },
      {
        id: 200,
        position: [
          1.8949467062314684, 0.021209868648581542, 0.5855819425941349,
        ],
        rotation: [
          -1.7084930616640155, -0.9039007589169298, -2.374657937912913,
        ],
      },
      {
        id: 199,
        position: [1.7049955127830179, -0.1845476545546174, 0.9990566045200284],
        rotation: [-1.9089363447126955, -0.72049397151256, -2.647845275411683],
      },
      {
        id: 198,
        position: [
          1.4064267086321627, -0.34239623195520497, 1.3557678922499963,
        ],
        rotation: [
          -2.0216682837750817, -0.5112137335181064, -2.839704551139258,
        ],
      },
      {
        id: 197,
        position: [1.02516501176255, -0.46793954454587927, 1.6699129323894648],
        rotation: [-2.081535898682763, -0.2902815836937387, -2.987541548142174],
      },
      {
        id: 196,
        position: [0.5508570381016318, -0.5339302197685045, 1.855873396719689],
        rotation: [
          -2.106464498974982, -0.05941917536374519, -3.118942825121871,
        ],
      },
      {
        id: 195,
        position: [
          0.05452861469022613, -0.5408278783892216, 1.9314153849075182,
        ],
        rotation: [-2.101635512112477, 0.16757898573532026, 3.039810258770026],
      },
      {
        id: 194,
        position: [
          -0.4337035436965784, -0.48518994354395517, 1.9034332140538681,
        ],
        rotation: [
          -2.0663918148207223, 0.39268664485613347, 2.9057758617027645,
        ],
      },
      {
        id: 193,
        position: [
          -0.9012469681655395, -0.3867297644079325, 1.7774346676115635,
        ],
        rotation: [-1.9895744968565083, 0.60764694380169, 2.745029986292434],
      },
      {
        id: 192,
        position: [
          -1.3247989032390564, -0.19471395073145592, 1.4993194446827967,
        ],
        rotation: [-1.841351730121664, 0.8167007625318199, 2.517020935113094],
      },
      {
        id: 191,
        position: [
          -1.6483712845059082, 0.03046270213323532, 1.1590657054618991,
        ],
        rotation: [-1.5862205509179599, 0.9795135484257056, 2.1918287959485294],
      },
      {
        id: 190,
        position: [
          -1.8456401882786886, 0.27054564687376237, 0.7641090230782467,
        ],
        rotation: [-1.1847989965601036, 1.0689419433355383, 1.7227552445426815],
      },
      {
        id: 189,
        position: [-1.9180793611336524, 0.5288936660811672, 0.3481614714054291],
        rotation: [-0.7261015426444466, 1.0474475741409268, 1.1967177905354063],
      },
      {
        id: 188,
        position: [
          -1.8718925940026454, 0.7881265140692726, -0.07013583222818853,
        ],
        rotation: [
          -0.37404327262944986, 0.9214698046710419, 0.7732971599373684,
        ],
      },
      {
        id: 187,
        position: [
          -1.6915461890504067, 1.0398153792374638, -0.4805956664993486,
        ],
        rotation: [-0.1667767779179616, 0.7365670572017908, 0.4900852161175494],
      },
      {
        id: 186,
        position: [
          -1.4247914141449318, 1.2562756251676288, -0.8186668257889137,
        ],
        rotation: [
          -0.05138201420580278, 0.5208196201227753, 0.29139095615390975,
        ],
      },
      {
        id: 185,
        position: [-1.0358991636484958, 1.4236692782273728, -1.080700269386275],
        rotation: [
          0.005358120989054235, 0.30390303639051797, 0.1446491135477742,
        ],
      },
      {
        id: 184,
        position: [
          -0.5573250791855577, 1.5217079397106377, -1.2335923723226438,
        ],
        rotation: [
          0.027864750967450086, 0.07809952856685842, 0.015343311036820921,
        ],
      },
      {
        id: 183,
        position: [
          -0.07777815468822138, 1.5653539629588225, -1.2649042646894237,
        ],
        rotation: [
          0.02263148652585909, -0.14209334345347568, -0.10537493736798151,
        ],
      },
      {
        id: 182,
        position: [0.4522648692621647, 1.563596068950005, -1.2453501194741432],
        rotation: [
          -0.013785395792258376, -0.37388728037293206, -0.24332834343578078,
        ],
      },
      {
        id: 181,
        position: [0.9054196899516135, 1.502156079520857, -1.0826827841953448],
        rotation: [
          -0.08957023914156972, -0.5885080697064156, -0.4013876890154401,
        ],
      },
      {
        id: 180,
        position: [1.3350018258180827, 1.3655085497672377, -0.8243789928366447],
        rotation: [
          -0.22857592039452682, -0.7915377764343767, -0.6162290462362101,
        ],
      },
      {
        id: 179,
        position: [1.6460811353553244, 1.1937704216072207, -0.4400136692412835],
        rotation: [
          -0.47434058906183335, -0.958425967838053, -0.9310756522868926,
        ],
      },
      {
        id: 178,
        position: [
          1.8421967701989206, 1.0114843674093712, -0.06121616441877947,
        ],
        rotation: [-0.8672126692568711, -1.055973760006765, -1.392849078848378],
      },
      {
        id: 177,
        position: [1.9210323552637045, 0.7867191604366085, 0.3965179998107439],
        rotation: [
          -1.3215288756757968, -1.041401948728716, -1.9127354753011439,
        ],
      },
      {
        id: 176,
        position: [1.8809291349241222, 0.5545253343428103, 0.865650764062873],
        rotation: [
          -1.6798569981515463, -0.9203992965069079, -2.3399373433586153,
        ],
      },
      {
        id: 175,
        position: [1.6910623205588202, 0.35993996765603575, 1.2945941536632166],
        rotation: [-1.893149567273623, -0.7399974382087267, -2.625607256227843],
      },
      {
        id: 174,
        position: [1.4032348473331555, 0.1894958756018571, 1.668201000983775],
        rotation: [
          -2.014130214911117, -0.5287188114473829, -2.8258572634743206,
        ],
      },
      {
        id: 173,
        position: [0.9883518960030315, 0.07828842303784156, 1.949004691632783],
        rotation: [
          -2.0778317628072003, -0.30596588648621825, -2.978309180836587,
        ],
      },
      {
        id: 172,
        position: [0.5430823371901367, 0.0172067973163742, 2.145708936768834],
        rotation: [
          -2.104366850059242, -0.07676129791849884, -3.1101002634786155,
        ],
      },
      {
        id: 171,
        position: [
          0.05972429900638679, 0.0037812571201645273, 2.215600350529755,
        ],
        rotation: [-2.101628861326475, 0.14913170922235097, 3.0482713847053002],
      },
      {
        id: 170,
        position: [
          -0.4341550179860351, 0.05165484205341808, 2.1881779594324917,
        ],
        rotation: [-2.068543453001457, 0.3734463088625355, 2.914853119146523],
      },
      {
        id: 169,
        position: [
          -0.9027537869968474, 0.16422515229623313, 2.0485374713466036,
        ],
        rotation: [-1.9970761483939523, 0.589909385751707, 2.757653968188639],
      },
      {
        id: 168,
        position: [-1.310829899324669, 0.35597390716797717, 1.7574019821101987],
        rotation: [-1.8637918505271678, 0.7956943846508193, 2.5456420960154604],
      },
      {
        id: 167,
        position: [-1.6165488767402851, 0.5279091521934152, 1.497174086678775],
        rotation: [-1.6280057475684377, 0.9653507459628679, 2.239704290804221],
      },
      {
        id: 166,
        position: [-1.8359228583521072, 0.7868368647946686, 1.0807080689655357],
        rotation: [-1.2378180917802974, 1.0677424037243162, 1.7813367724589888],
      },
      {
        id: 165,
        position: [-1.9203266716766125, 1.0475715189863082, 0.6692449254347457],
        rotation: [-0.7660034159630476, 1.0580928766110613, 1.2417578544559364],
      },
      {
        id: 164,
        position: [
          -1.8674105232217506, 1.3227842776349092, 0.21595713982891918,
        ],
        rotation: [
          -0.39165279627156324, 0.9356443949505353, 0.7958682273203919,
        ],
      },
      {
        id: 163,
        position: [
          -1.6899735917487235, 1.5590823985348614, -0.1634391414420786,
        ],
        rotation: [-0.1803186972229524, 0.7586549512721898, 0.5114665818426769],
      },
      {
        id: 162,
        position: [
          -1.3913350152718977, 1.7705948780701928, -0.5088790417381353,
        ],
        rotation: [
          -0.05758131309666112, 0.5428898053214848, 0.3055354498866715,
        ],
      },
      {
        id: 161,
        position: [-1.012864300140321, 1.933848935852869, -0.7656098681554648],
        rotation: [
          0.003824214141326901, 0.32306490407799515, 0.15467217779623038,
        ],
      },
      {
        id: 160,
        position: [
          -0.5405970488998225, 2.0561156625694914, -0.9453444828843147,
        ],
        rotation: [
          0.028852428583587778, 0.09385648750553144, 0.023910248987946334,
        ],
      },
      {
        id: 159,
        position: [
          -0.047841876996922314, 2.1043593079358285, -0.9836279916824664,
        ],
        rotation: [
          0.02363789912748679, -0.12987149848017965, -0.09805230499234371,
        ],
      },
      {
        id: 158,
        position: [0.45771387235550987, 2.094180048365941, -0.9243336742709878],
        rotation: [
          -0.009119090676711239, -0.3545832665444595, -0.22917449731965567,
        ],
      },
      {
        id: 157,
        position: [0.9206848913257349, 2.0217012123662452, -0.7605518656978782],
        rotation: [
          -0.08422806640779729, -0.5792095281307441, -0.3906893450368247,
        ],
      },
      {
        id: 156,
        position: [1.318737945057936, 1.8933339615560636, -0.4756134858583013],
        rotation: [
          -0.2161055060021108, -0.7787174564150786, -0.5967946927093206,
        ],
      },
      {
        id: 155,
        position: [1.6327429993155205, 1.741875037614042, -0.15279649228458742],
        rotation: [
          -0.45299002987459447, -0.9515505556257383, -0.9060483513643945,
        ],
      },
      {
        id: 154,
        position: [1.833922034024649, 1.5298296413600163, 0.2607381614807127],
        rotation: [
          -0.8377759390078483, -1.0549758014792676, -1.3595241666565012,
        ],
      },
      {
        id: 153,
        position: [1.9134459512334263, 1.3268872617846172, 0.6956664357663003],
        rotation: [-1.297264201355286, -1.0471516392495668, -1.885282867061786],
      },
      {
        id: 152,
        position: [1.8794382926204514, 1.1070806653531924, 1.146667539996641],
        rotation: [
          -1.6670974595603825, -0.9299432866628211, -2.323914527505594,
        ],
      },
      {
        id: 151,
        position: [1.6950349684163228, 0.8925901171060944, 1.5918288654045032],
        rotation: [-1.8839592169275496, -0.753438423743755, -2.611098512016677],
      },
      {
        id: 150,
        position: [1.397154384887337, 0.7355008594496724, 1.9638043782791392],
        rotation: [
          -2.0076843113877962, -0.5481015509063871, -2.812357984258226,
        ],
      },
      {
        id: 149,
        position: [1.0039051191118158, 0.6173583470817915, 2.245576263408072],
        rotation: [-2.075159329655754, -0.32499976134245445, -2.96753288508784],
      },
      {
        id: 148,
        position: [0.5658294934095941, 0.5700108946806828, 2.4116631780587108],
        rotation: [
          -2.1060194920151907, -0.09858473120018152, -3.0987069815345047,
        ],
      },
    ],
  },
  {
    id: '107',
    name: 'tower/0527_GPS',
    file: 'point_cloud_0527_GPS.splat',
    viewSettings: {
      camera: {
        position: [-2932.46, 295.01, -8179.49],
        rotation: [-178.43, -56.14, -85.73],
        // position: [-24.43, -769.63, -5121.34],
        // rotation: [-151.24, -7.31, 176.04],
        // position: [0, 0, 100],
        // rotation: [0, 0, 0],
        // position: [-8.591, 2.577, -350.33],
        // rotation: [2.107, -0.02933, -1.9534],
        // position: [0, 5, 20],
        // rotation: [0, 0, 0],
        fov: 75,
        near: 0.000000000000000000001,
        far: 10000000000000000000000000000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 2000000,
      maxPanY: 2000000,
      maxPanZ: 2000000,
    },
    cameraData: [
      {
        id: 302,
        position: [-8.591072889250313, 2.5772426338988907, 350.33955986122817],
        quaternion: [
          -0.49251182671639315, 0.716164748027146, -0.4167798533616609,
          0.26614790635946867,
        ],
      },
      {
        id: 289,
        position: [8.71768509519228, -0.1579526092287047, 348.77556776603217],
        quaternion: [
          -0.6498710595317662, -0.5706720522767011, 0.3347300306631133,
          0.37410803427035516,
        ],
      },
      {
        id: 229,
        position: [-9.300415520384831, 0.12869648486184104, 342.1109016780245],
        quaternion: [
          -0.5827719296556444, 0.6484579217269186, -0.37644695456040844,
          0.3133159621807291,
        ],
      },
      {
        id: 298,
        position: [-6.5441876572467095, -6.028986019264735, 350.03188451302213],
        quaternion: [
          -0.7851769874513016, 0.3810789939095956, -0.2197869964873643,
          0.43585499303416564,
        ],
      },
      {
        id: 131,
        position: [-8.064166940090416, -4.426956371781003, 331.6050212127424],
        quaternion: [
          -0.7348479908371804, 0.47229299411097875, -0.2677039966619989,
          0.4065369949308903,
        ],
      },
      {
        id: 89,
        position: [-4.335851725234235, 7.490275849663448, 326.6420983915238],
        quaternion: [
          -0.19222899462047166, 0.8452379763460156, -0.4895179863008393,
          0.09483099734615458,
        ],
      },
      {
        id: 307,
        position: [0.09327679492475838, 9.011635437537933, 351.1189341182272],
        quaternion: [
          -0.0462099848952523, -0.8609057185940721, 0.5052178348584629,
          0.038221987506304546,
        ],
      },
      {
        id: 81,
        position: [-4.608314333334334, -7.59184386756003, 326.01970613522184],
        quaternion: [
          -0.8322451606749307, 0.26737905162073944, -0.1496910288996522,
          0.4620270891998825,
        ],
      },
      {
        id: 373,
        position: [-8.29520893321262, 0.41885824164119256, 358.62073163579964],
        quaternion: [
          -0.5861131121808881, 0.6429051230507665, -0.37410907160373497,
          0.32122706148222296,
        ],
      },
      {
        id: 197,
        position: [4.101458353662736, -7.854224527670482, 338.83170138575684],
        quaternion: [
          -0.8531246325622859, -0.17114192628978722, 0.10399795520845433,
          0.48173979251640214,
        ],
      },
      {
        id: 279,
        position: [-7.78021250478028, 4.526372361541419, 347.67178221786503],
        quaternion: [
          -0.39488125516721606, 0.7732104996387346, -0.44975829062805445,
          0.2095991354402803,
        ],
      },
      {
        id: 281,
        position: [-4.551121279729877, 7.7187095748256525, 347.8103603988574],
        quaternion: [
          -0.17911892519189213, 0.8461016466299517, -0.4942167935928704,
          0.08819396316623995,
        ],
      },
      {
        id: 268,
        position: [6.0433804069270565, -6.6076466352563585, 346.622233614723],
        quaternion: [
          -0.8224000290928127, -0.27838800984811524, 0.16677600589978472,
          0.4672730165300169,
        ],
      },
      {
        id: 305,
        position: [-4.3250787217073565, 7.797582456319049, 350.6051235205804],
        quaternion: [
          -0.18268102157892294, 0.8449100998037441, -0.49448605841042736,
          0.09076401072136325,
        ],
      },
      {
        id: 367,
        position: [
          -0.038224051189082786, -8.269342714191652, 358.18882162222167,
        ],
        quaternion: [
          -0.8699223191880413, 0.05098301870646323, -0.023440008600504054,
          0.48998617978355713,
        ],
      },
      {
        id: 255,
        position: [-7.898521428566312, 4.722837887522076, 344.8112689636101],
        quaternion: [
          -0.39214190672650967, 0.7741648158598884, -0.4517898925388502,
          0.20683195080368194,
        ],
      },
      {
        id: 90,
        position: [-2.1452765099803273, 8.24055543010985, 326.73633639588894],
        quaternion: [
          -0.080345991257513, 0.8611519062976356, -0.5009759454885598,
          0.03138499658498302,
        ],
      },
      {
        id: 294,
        position: [2.042514677951793, -8.586397288880534, 349.69815456049133],
        quaternion: [
          -0.8688889804660727, -0.056802998722983396, 0.03714699916487975,
          0.4903319889766015,
        ],
      },
      {
        id: 225,
        position: [-4.761670964181093, -7.752780626544137, 341.98582911941185],
        quaternion: [
          -0.8270089970740423, 0.27905699901269637, -0.1586349994387494,
          0.46153899836707507,
        ],
      },
      {
        id: 111,
        position: [-7.846012558522929, 4.572736403151367, 329.2413709956117],
        quaternion: [
          -0.40419896204915495, 0.7683869278550022, -0.44717795801379273,
          0.21502697981079527,
        ],
      },
      {
        id: 166,
        position: [6.35023956265556, 6.215144890680875, 335.1835588267641],
        quaternion: [
          -0.3679049406506835, -0.7788368743603822, 0.4582479260768253,
          0.21924396463222423,
        ],
      },
      {
        id: 311,
        position: [7.640995356435695, 4.473958858936491, 351.425482201671],
        quaternion: [
          -0.4759378174262428, -0.7193537240498499, 0.42306883770722886,
          0.2775358935348926,
        ],
      },
      {
        id: 230,
        position: [-8.85359145656227, 2.5529236638302635, 342.0807269605896],
        quaternion: [
          -0.4934880367391987, 0.716556053346167, -0.4171600310567311,
          0.26266801955510943,
        ],
      },
      {
        id: 26,
        position: [7.805123572222868, -2.390146702207204, 319.70542574081185],
        quaternion: [
          -0.7038200000640477, -0.5043000000458913, 0.2973870000270622,
          0.4023430000366132,
        ],
      },
      {
        id: 274,
        position: [-6.629759611724708, -6.382792476203335, 347.3891678170736],
        quaternion: [
          -0.7857919811861758, 0.3802819908950985, -0.21729599479739067,
          0.43669098954452606,
        ],
      },
      {
        id: 25,
        position: [8.06680927078014, -0.36458508753426866, 319.53563305968675],
        quaternion: [
          -0.6304699420078013, -0.5920739455395608, 0.3479499679946936,
          0.36178296672229987,
        ],
      },
      {
        id: 156,
        position: [-9.043294550328852, -2.1264129522988107, 334.3475247506228],
        quaternion: [
          -0.6690840182569614, 0.5608830153045347, -0.32148900877231,
          0.36658101000271287,
        ],
      },
      {
        id: 214,
        position: [6.398007469076589, 6.306829109636368, 340.59808964137983],
        quaternion: [
          -0.37517181510018066, -0.7755336177857182, 0.45536977557538744,
          0.2245708893223979,
        ],
      },
      {
        id: 40,
        position: [-5.755930934023937, 5.7862044126841, 321.29313827999454],
        quaternion: [
          -0.3070590543546775, 0.8111001435785269, -0.4703290832562507,
          0.16316202888245543,
        ],
      },
      {
        id: 44,
        position: [2.0315237998686158, 7.640064048195981, 321.60042703159985],
        quaternion: [
          -0.1375499630710723, -0.8521857712081775, 0.49727586649313377,
          0.08704097663154636,
        ],
      },
      {
        id: 13,
        position: [
          -8.312494549119748, -0.002444461489368166, 318.5003062978345,
        ],
        quaternion: [
          -0.6037432928312654, 0.6292573052062277, -0.3598771745498288,
          0.33168916087790595,
        ],
      },
      {
        id: 354,
        position: [-1.9494503814643345, 8.421620924492117, 356.4788783727025],
        quaternion: [
          -0.07750401066079367, 0.8593161182002291, -0.5046730694185425,
          0.029564004066573387,
        ],
      },
      {
        id: 355,
        position: [0.2884545130827796, 8.702718280325286, 356.5431921746308],
        quaternion: [
          -0.037744998667620454, -0.860716969617122, 0.5064459821227104,
          0.03541299874993888,
        ],
      },
      {
        id: 144,
        position: [8.56561531815629, 2.1381522334098584, 332.7827645290648],
        quaternion: [
          -0.5590000541701825, -0.6569820636651784, 0.3885410376517654,
          0.3239280313904094,
        ],
      },
      {
        id: 297,
        position: [-4.660994196066952, -7.553731692865512, 349.97320496341104],
        quaternion: [
          -0.8277068728551293, 0.27676195748632226, -0.1587599756127233,
          0.4616269290890313,
        ],
      },
      {
        id: 249,
        position: [-4.9252500267237735, -7.796807382128989, 344.69088662010125],
        quaternion: [
          -0.8271997307808603, 0.27968690897353293, -0.15781194863876827,
          0.4610978499318098,
        ],
      },
      {
        id: 345,
        position: [-4.286699859668872, -7.1894312450477855, 355.6834642217983],
        quaternion: [
          -0.8305238273665047, 0.2697369439322149, -0.15239596832282493,
          0.46286890378761675,
        ],
      },
      {
        id: 237,
        position: [4.432232474943206, 7.796451919807552, 342.9701018631109],
        quaternion: [
          -0.27067393645212806, -0.8169518081989364, 0.4823068867656905,
          0.16341796163330746,
        ],
      },
      {
        id: 290,
        position: [8.374839628390816, -2.45169170893557, 349.01916921531966],
        quaternion: [
          -0.7198470557784311, -0.48127903729262955, 0.2824890218890864,
          0.4127840319851911,
        ],
      },
      {
        id: 38,
        position: [-8.106172043992899, 2.0834035924917345, 321.1487212940883],
        quaternion: [
          -0.5096950767343448, 0.7055041062132984, -0.40691606126101415,
          0.27729804174708467,
        ],
      },
      {
        id: 246,
        position: [1.9327131146603236, -8.88818859569281, 344.2121868618034],
        quaternion: [
          -0.8690406886566696, -0.05351598082731463, 0.03684298680059707,
          0.49045582428883744,
        ],
      },
      {
        id: 20,
        position: [2.086364249962621, 7.645948147438546, 319.00070443248603],
        quaternion: [
          -0.13321500415051335, -0.8529100265736916, 0.4975110155007022,
          0.08532900265855312,
        ],
      },
      {
        id: 42,
        position: [-2.1794993273453045, 7.785996107977468, 321.38214543016727],
        quaternion: [
          -0.09023702135197675, 0.8597622034378164, -0.501032118554735,
          0.040411009562094626,
        ],
      },
      {
        id: 1,
        position: [7.9392350259674345, 0.03360950781352301, 316.9232365764323],
        quaternion: [
          -0.6375682239567166, -0.58415320519378, 0.34438712097185203,
          0.36560812842609297,
        ],
      },
      {
        id: 184,
        position: [-6.450649669756032, 6.510278427285854, 337.34653557877215],
        quaternion: [
          -0.2983299834173283, 0.8137099547699336, -0.47384297366143907,
          0.15603899132657292,
        ],
      },
      {
        id: 57,
        position: [-4.60849048696258, -7.524552091330719, 323.277455269533],
        quaternion: [
          -0.8342120255923741, 0.26080700800116796, -0.14496300444724763,
          0.46374101422687897,
        ],
      },
      {
        id: 293,
        position: [4.191961194016159, -7.723919908865241, 349.4996042199296],
        quaternion: [
          -0.8532987406027688, -0.1678199489838341, 0.10171896907809928,
          0.48308485314536703,
        ],
      },
      {
        id: 310,
        position: [6.280048253631833, 6.29846415486233, 351.345517385349],
        quaternion: [
          -0.37422992523223647, -0.7759198449782138, 0.4566389087676649,
          0.22221895560265706,
        ],
      },
      {
        id: 123,
        position: [7.464201529363322, -4.617764950753767, 330.4803570896163],
        quaternion: [
          -0.7748241144810463, -0.390843057747457, 0.23231203432433795,
          0.4392270648962429,
        ],
      },
      {
        id: 129,
        position: [-4.803689989029692, -7.8708912661954145, 331.41180802211085],
        quaternion: [
          -0.8305709265513704, 0.26916297619751534, -0.14940198678815883,
          0.46409295895956537,
        ],
      },
      {
        id: 73,
        position: [8.535824091098306, -0.20374941649047962, 324.8441046256253],
        quaternion: [
          -0.6411151129263465, -0.5802071021979781, 0.3415860601671447,
          0.3683070648737963,
        ],
      },
      {
        id: 41,
        position: [-4.050513039023905, 7.049808444044402, 321.32024094653326],
        quaternion: [
          -0.20284101505131097, 0.841753062460184, -0.4893810363132977,
          0.10398800771617044,
        ],
      },
      {
        id: 61,
        position: [-8.590079595998844, 0.11498498163281035, 323.70227973350785],
        quaternion: [
          -0.5937880323489791, 0.6390310348137728, -0.36679601998268097,
          0.32328901761246287,
        ],
      },
      {
        id: 336,
        position: [8.44049023404665, 2.1934866157499613, 354.13580419131443],
        quaternion: [
          -0.5599391115216842, -0.656698130792938, 0.3856040767998076,
          0.32638206500470646,
        ],
      },
      {
        id: 162,
        position: [-2.343877566934271, 8.661880788172596, 334.7846500315803],
        quaternion: [
          -0.07662998728930843, 0.8596148574148358, -0.5041789163713459,
          0.0315239947710839,
        ],
      },
      {
        id: 2,
        position: [7.501443490329962, -2.44906189626316, 317.1133732163564],
        quaternion: [
          -0.7002400218667456, -0.5096000159135348, 0.30066100938889184,
          0.3994680124743875,
        ],
      },
      {
        id: 208,
        position: [-6.61282188372175, 6.629211273536399, 339.9658826026152],
        quaternion: [
          -0.2985799858932903, 0.8128979615938238, -0.4755419775325443,
          0.1546179926949185,
        ],
      },
      {
        id: 216,
        position: [8.71093352981319, 2.264678361509194, 340.62694420024553],
        quaternion: [
          -0.5677991407795687, -0.6498721611286737, 0.3824360948208347,
          0.330171081862298,
        ],
      },
      {
        id: 295,
        position: [-0.2754717249830847, -8.929295670949415, 349.8353286002946],
        quaternion: [
          -0.8700049523667952, 0.05506399698521872, -0.02834399844815196,
          0.4891379732194522,
        ],
      },
      {
        id: 46,
        position: [5.730477644376762, 5.695630901383225, 321.81425286213766],
        quaternion: [
          -0.35629503141168395, -0.7862330693158829, 0.4593390404962503,
          0.20952101847179286,
        ],
      },
      {
        id: 14,
        position: [-7.96836815517068, 2.0385674552224415, 318.58201968859146],
        quaternion: [
          -0.5151612264335, 0.7023953087301995, -0.40297617712378486,
          0.2808201234314234,
        ],
      },
      {
        id: 24,
        position: [7.700924464421803, 1.5802037636818191, 319.4238222801372],
        quaternion: [
          -0.5450208890045997, -0.6701238635269438, 0.3937969198018872,
          0.3143409359833747,
        ],
      },
      {
        id: 340,
        position: [6.017831336468461, -6.111505491167662, 354.8128850644481],
        quaternion: [
          -0.8204078668871936, -0.2848179537877211, 0.16844597266930628,
          0.4662999243419108,
        ],
      },
      {
        id: 205,
        position: [-9.499758807182815, 0.23258978645590034, 339.7351868649689],
        quaternion: [
          -0.5890530126384271, 0.6416200137662783, -0.3729340080014857,
          0.31978200686108293,
        ],
      },
      {
        id: 222,
        position: [1.9371711495028379, -8.759220128539368, 341.82669071547025],
        quaternion: [
          -0.8690797896640312, -0.05536898659951644, 0.035068991512551106,
          0.49031088133423945,
        ],
      },
      {
        id: 207,
        position: [-8.078620759523147, 4.75086243543025, 339.9066946498026],
        quaternion: [
          -0.3995449585140505, 0.7701919200286665, -0.4499589532794145,
          0.21143597804596925,
        ],
      },
      {
        id: 48,
        position: [7.769702877334481, 1.9723357754842041, 321.9820683033645],
        quaternion: [
          -0.5535961188792433, -0.6644101426754493, 0.38944008362837257,
          0.3169020680515574,
        ],
      },
      {
        id: 146,
        position: [8.520286824233153, -2.4820829180759603, 333.0257451584697],
        quaternion: [
          -0.7130097989804627, -0.4902838617738, 0.2925449175225305,
          0.4070088852515941,
        ],
      },
      {
        id: 313,
        position: [8.731160224100961, 0.034474364113453504, 351.7393912877445],
        quaternion: [
          -0.6490363924587954, -0.5722433460236389, 0.33549920286938384,
          0.3724642252213634,
        ],
      },
      {
        id: 120,
        position: [8.492456144073174, 2.00529602209377, 330.08747428695716],
        quaternion: [
          -0.5593199530042616, -0.6575209447531201, 0.386876967493438,
          0.32427397275352915,
        ],
      },
      {
        id: 113,
        position: [-4.45094241858115, 7.743729351819439, 329.36660695059237],
        quaternion: [
          -0.19019104907890166, 0.8440162177988352, -0.4923811270592125,
          0.09499902451454895,
        ],
      },
      {
        id: 78,
        position: [1.914065373222087, -8.573847756665401, 325.61615769101104],
        quaternion: [
          -0.8685545664797703, -0.0678979661101985, 0.04645197681450028,
          0.4887177560671004,
        ],
      },
      {
        id: 12,
        position: [-7.9971210694614, -2.0836405083539553, 318.4269104528953],
        quaternion: [
          -0.6791980702318009, 0.5477560566401702, -0.3125140323152027,
          0.37548403882655357,
        ],
      },
      {
        id: 350,
        position: [-8.078660767995757, 2.6112434874022696, 356.043181429677],
        quaternion: [
          -0.4979850502066064, 0.7124440718282588, -0.41403004174230396,
          0.27021002724244125,
        ],
      },
      {
        id: 11,
        position: [-7.240209307315553, -4.167085320138092, 318.33594680354514],
        quaternion: [
          -0.7464396279275565, 0.4535287739327432, -0.2573378717266245,
          0.4134207939250822,
        ],
      },
      {
        id: 18,
        position: [-2.2159741768866006, 7.518633976389594, 318.8362949590627],
        quaternion: [
          -0.09377497149077195, 0.8604357384125177, -0.4989748483029372,
          0.0433669868156791,
        ],
      },
      {
        id: 134,
        position: [-8.739548690506439, 2.4533493329744687, 331.90283061622955],
        quaternion: [
          -0.49705298015416616, 0.7141039714879713, -0.41385498347601246,
          0.26780198930747023,
        ],
      },
      {
        id: 114,
        position: [-2.4119644598248478, 8.59751070721208, 329.43493116651905],
        quaternion: [
          -0.07758398971232487, 0.8602618859288514, -0.5029259333117743,
          0.03155499581579206,
        ],
      },
      {
        id: 63,
        position: [-7.679192839571996, 4.343273672857038, 323.86052120523846],
        quaternion: [
          -0.4085398110036033, 0.7672696450500189, -0.4439797946085568,
          0.21742689941518692,
        ],
      },
      {
        id: 233,
        position: [-4.550459363189923, 7.851461173358388, 342.2973389445667],
        quaternion: [
          -0.17903202309182037, 0.8458471090986359, -0.49475206381386744,
          0.0878100113258677,
        ],
      },
      {
        id: 356,
        position: [2.4485946916207837, 8.370788535458104, 356.5946847692758],
        quaternion: [
          -0.15144302501884413, -0.8476601400360098, 0.4987360823927039,
          0.09899301635394464,
        ],
      },
      {
        id: 359,
        position: [7.473095137440708, 4.197139419549259, 356.8002637797723],
        quaternion: [
          -0.4714370230949932, -0.7223650353875805, 0.4230650207253213,
          0.2774020135895089,
        ],
      },
      {
        id: 266,
        position: [8.477908102003937, -2.510380055104072, 346.1091629526157],
        quaternion: [
          -0.719918969869592, -0.4809819798697022, 0.2847459880826688,
          0.4114519827797063,
        ],
      },
      {
        id: 344,
        position: [-2.22089213120411, -8.081268349216458, 355.63311732171724],
        quaternion: [
          -0.8579398172910111, 0.16099796571347436, -0.08857498113685258,
          0.47976389782829176,
        ],
      },
      {
        id: 15,
        position: [-7.093600335343012, 3.8974716398025606, 318.6647395520655],
        quaternion: [
          -0.41941618647247797, 0.7618523387196251, -0.4385841949945764,
          0.22652810071441598,
        ],
      },
      {
        id: 56,
        position: [-2.4404525265027317, -8.4068393067528, 323.1473365207454],
        quaternion: [
          -0.8607512652177334, 0.14930204600347605, -0.08011202468440125,
          0.4799981478987321,
        ],
      },
      {
        id: 74,
        position: [8.164980927020224, -2.599946169377425, 324.9768339703711],
        quaternion: [
          -0.7133689358485179, -0.49096195584902075, 0.28993297392705364,
          0.40743096336075374,
        ],
      },
      {
        id: 161,
        position: [-4.587591536502508, 7.7791561383969565, 334.7426317353541],
        quaternion: [
          -0.18975802312410386, 0.8435681027980588, -0.4934070601270814,
          0.09452101151842568,
        ],
      },
      {
        id: 43,
        position: [-0.14348468784300117, 7.97162838084725, 321.49805407822333],
        quaternion: [
          -0.02540099806359309, -0.8632079341946405, 0.503628961606603,
          0.02418099815659795,
        ],
      },
      {
        id: 154,
        position: [-6.691463361103715, -6.362766594110909, 334.1918684310527],
        quaternion: [
          -0.7915418762547063, 0.36883194233884725, -0.20925896728560656,
          0.4400399312065828,
        ],
      },
      {
        id: 143,
        position: [7.727762370742937, 4.323815520328289, 332.6154187974486],
        quaternion: [
          -0.46498627611404764, -0.7254314307692913, 0.42771125397972287,
          0.2731301621877429,
        ],
      },
      {
        id: 168,
        position: [8.601303458384109, 2.1352355708504938, 335.4220794301342],
        quaternion: [
          -0.5600659466195571, -0.6563629374413952, 0.385910963218442,
          0.3264759688832504,
        ],
      },
      {
        id: 217,
        position: [8.912956165004289, -0.11051148899766616, 340.65133837319877],
        quaternion: [
          -0.6494599527927062, -0.5702169585526414, 0.3360939755703381,
          0.37429297279376766,
        ],
      },
      {
        id: 19,
        position: [
          -0.009195986411528523, 7.834116827853364, 318.91696535343465,
        ],
        quaternion: [
          -0.020667995713437467, -0.8640808207887923, 0.5024808957849706,
          0.021215995599781753,
        ],
      },
      {
        id: 338,
        position: [8.312360219405008, -2.1841480191585916, 354.343246555299],
        quaternion: [
          -0.7150252206439467, -0.4879211505637077, 0.2864190883837887,
          0.41065312672018267,
        ],
      },
      {
        id: 366,
        position: [2.165669724230728, -8.029885266931927, 358.09162624675776],
        quaternion: [
          -0.8681915679263418, -0.06273796877714012, 0.04185297917099119,
          0.49046475590997524,
        ],
      },
      {
        id: 342,
        position: [2.0878732975973655, -8.211664771864406, 355.1950860696463],
        quaternion: [
          -0.8689750925588869, -0.06242500664919994, 0.040778004343469364,
          0.4892060521077854,
        ],
      },
      {
        id: 191,
        position: [7.727160543461764, 4.364155293435488, 337.8908232050122],
        quaternion: [
          -0.47061198892673534, -0.7230849829861721, 0.42372299003001007,
          0.27591899350776417,
        ],
      },
      {
        id: 220,
        position: [6.23366760692511, -6.6579393089894126, 341.26818533426456],
        quaternion: [
          -0.8242982359793085, -0.2744930785816153, 0.16294204664689285,
          0.4675851338598237,
        ],
      },
      {
        id: 301,
        position: [-8.931271150835926, 0.2971045211243357, 350.2221821627654],
        quaternion: [
          -0.5823270974006208, 0.6473581082777735, -0.37568706283779907,
          0.3173040530725976,
        ],
      },
      {
        id: 265,
        position: [8.752146459982924, -0.24050439258387257, 345.8938119519843],
        quaternion: [
          -0.6489924463988994, -0.5714223930435998, 0.33680323166462534,
          0.3726242563035345,
        ],
      },
      {
        id: 270,
        position: [1.8745931288819229, -8.788212386500845, 347.01457134503585],
        quaternion: [
          -0.8685965489561698, -0.05566297109539554, 0.03822398015109497,
          0.49089774508717604,
        ],
      },
      {
        id: 58,
        position: [-6.350432050182405, -6.067335370761242, 323.423718653747],
        quaternion: [
          -0.7930150025887975, 0.36681400119746427, -0.2077210006781052,
          0.4398030014357369,
        ],
      },
      {
        id: 306,
        position: [-2.1775577137814466, 8.736522908128165, 350.7918459515639],
        quaternion: [
          -0.06798998895006393, 0.8604428601582564, -0.5043239180357706,
          0.025938995784316934,
        ],
      },
      {
        id: 247,
        position: [
          -0.43707221647064287, -9.029768441275337, 344.47817302109553,
        ],
        quaternion: [
          -0.8693941864298664, 0.06101301308341838, -0.029141006248896057,
          0.48947110496048185,
        ],
      },
      {
        id: 201,
        position: [-5.0945458778329, -7.756684690587875, 339.5002151244079],
        quaternion: [
          -0.829405171457143, 0.27244405632045854, -0.156047032258514,
          0.4620720955209397,
        ],
      },
      {
        id: 213,
        position: [4.5268757421559656, 7.807667500862905, 340.4994777041237],
        quaternion: [
          -0.26715101939048963, -0.8190470594484853, 0.4805930348826452,
          0.16377601188727284,
        ],
      },
      {
        id: 202,
        position: [-6.9552889802316855, -6.276422023541215, 339.58781589558464],
        quaternion: [
          -0.7879337528736785, 0.37587588211087064, -0.21631593215500616,
          0.4371328628983261,
        ],
      },
      {
        id: 180,
        position: [-9.074022941642113, -2.088491595746113, 337.0710886520004],
        quaternion: [
          -0.667846169353121, 0.5622771425828182, -0.3226480818174336,
          0.3656830927302961,
        ],
      },
      {
        id: 309,
        position: [4.533373143663212, 7.765027287655926, 351.2984729624544],
        quaternion: [
          -0.2683310585886172, -0.8184141786962542, 0.4811771050623859,
          0.16329503565457681,
        ],
      },
      {
        id: 236,
        position: [2.226675957015786, 8.698054906896147, 342.85941650217785],
        quaternion: [
          -0.1592129489519584, -0.8458627287931915, 0.4987658400819812,
          0.10198096730210265,
        ],
      },
      {
        id: 241,
        position: [8.872733661469795, -0.11080297375315595, 343.25303619783926],
        quaternion: [
          -0.6485609456732947, -0.5718229521012524, 0.33773397170971503,
          0.3719179688462927,
        ],
      },
      {
        id: 122,
        position: [8.405089912615807, -2.5027306246291303, 330.39704142981753],
        quaternion: [
          -0.7148475728068607, -0.4887477079242126, 0.28931182710715914,
          0.40794275621327775,
        ],
      },
      {
        id: 187,
        position: [-0.0503023116874175, 9.085329570476489, 337.497056630153],
        quaternion: [
          -0.03786801635595716, -0.8613963720544013, 0.505368218278688,
          0.03413601474403067,
        ],
      },
      {
        id: 308,
        position: [2.3800988818501736, 8.624246903403133, 351.2595283310413],
        quaternion: [
          -0.15599292709344673, -0.8473476039743956, 0.49765776740924594,
          0.10002495325124851,
        ],
      },
      {
        id: 357,
        position: [4.464485453009047, 7.39484017256099, 356.66695430206283],
        quaternion: [
          -0.2633401014074208, -0.8200863158001294, 0.4817251855034927,
          0.16140406215373032,
        ],
      },
      {
        id: 10,
        position: [-5.895859220246493, -5.9152548604086945, 318.2013109824315],
        quaternion: [
          -0.7988699546904942, 0.35382297993222267, -0.19939398869097147,
          0.4436869748354066,
        ],
      },
      {
        id: 85,
        position: [-9.031450589480897, 0.12540155314334278, 326.40594057706267],
        quaternion: [
          -0.5930201233369311, 0.6393691329766454, -0.3675540764442723,
          0.32316906721303273,
        ],
      },
      {
        id: 62,
        position: [-8.507349176604635, 2.309851745965723, 323.7993122800521],
        quaternion: [
          -0.5053228005481193, 0.7092647200518518, -0.4088588386226305,
          0.2728128923202271,
        ],
      },
      {
        id: 86,
        position: [-8.62934299969288, 2.28734515174078, 326.48707985276536],
        quaternion: [
          -0.5040280633694363, 0.7101520892845872, -0.40938605147047397,
          0.2721080342110569,
        ],
      },
      {
        id: 343,
        position: [-0.06459368456178893, -8.4456059532815, 355.44055655330146],
        quaternion: [
          -0.8708531547062058, 0.05126400910700076, -0.024646004378338422,
          0.4882410867355485,
        ],
      },
      {
        id: 135,
        position: [-7.82626119676749, 4.652919839173521, 331.94887842568875],
        quaternion: [
          -0.40041793118217817, 0.7700588676538441, -0.4486949228850537,
          0.2129499634013577,
        ],
      },
      {
        id: 163,
        position: [-0.08374831796469451, 8.875923560831836, 334.84349285365],
        quaternion: [
          -0.0382550005916901, -0.861102013318665, 0.5059060078248483,
          0.033151000512746526,
        ],
      },
      {
        id: 96,
        position: [8.359498589766588, 1.9486991906292523, 327.34645364857414],
        quaternion: [
          -0.5556059788828063, -0.6614929748583063, 0.38796298525449713,
          0.32126898778936924,
        ],
      },
      {
        id: 353,
        position: [-3.9902602716177853, 7.565432740463422, 356.34566402038115],
        quaternion: [
          -0.18803701331490139, 0.8435070597287371, -0.4945910350219924,
          0.09229100653512641,
        ],
      },
      {
        id: 370,
        position: [-5.92312353205979, -5.610062118328699, 358.44925059497933],
        quaternion: [
          -0.7881247993674895, 0.37603690427248554, -0.21286394581133866,
          0.43834288841128427,
        ],
      },
      {
        id: 243,
        position: [7.586447541324034, -4.674436957194999, 343.5554250116917],
        quaternion: [
          -0.7797466816614644, -0.3815438442313235, 0.22509790810177188,
          0.4424368193712235,
        ],
      },
      {
        id: 141,
        position: [4.466838975787297, 7.622053801772793, 332.3721285302041],
        quaternion: [
          -0.25640105231928106, -0.8221871677693642, 0.4836270986853286,
          0.15611403185546174,
        ],
      },
      {
        id: 55,
        position: [-0.2323809305695832, -8.730343688473994, 323.01032666994485],
        quaternion: [
          -0.8720179952274456, 0.037369999795474, -0.01479099991904886,
          0.48782099733015577,
        ],
      },
      {
        id: 312,
        position: [8.509147639802308, 2.332236730819915, 351.57122989475897],
        quaternion: [
          -0.5688528487460297, -0.6505908270124763, 0.38146389857143315,
          0.32805991277117724,
        ],
      },
      {
        id: 3,
        position: [6.690175033033128, -4.333729228262683, 317.23211677625176],
        quaternion: [
          -0.76190182365251, -0.4141929041321641, 0.2455249431715398,
          0.43320589973147366,
        ],
      },
      {
        id: 260,
        position: [2.2451955109728416, 8.623128438366166, 345.4301585786631],
        quaternion: [
          -0.1534079843313695, -0.8466759135230667, 0.4995349489790015,
          0.10035298975024722,
        ],
      },
      {
        id: 284,
        position: [2.310232925336827, 8.63304107742323, 348.09243525682257],
        quaternion: [
          -0.15631394143527336, -0.8477406823846877, 0.49683681385465733,
          0.100273962431264,
        ],
      },
      {
        id: 27,
        position: [6.934449122398732, -4.392585522779896, 319.8370301358877],
        quaternion: [
          -0.7661980092441791, -0.4055010048923697, 0.2415590029144094,
          0.4360720052612088,
        ],
      },
      {
        id: 341,
        position: [4.163895844917171, -7.313750249878808, 355.0422935195508],
        quaternion: [
          -0.8520407563278811, -0.17475795002159267, 0.10504796995770303,
          0.4821318621168159,
        ],
      },
      {
        id: 84,
        position: [-8.703798566866965, -2.2397859043334343, 326.30622606740525],
        quaternion: [
          -0.6708171625832922, 0.558633135393695, -0.3204130776572454,
          0.36778908913958114,
        ],
      },
      {
        id: 87,
        position: [-7.667632984612425, 4.428081718863524, 326.5526998757446],
        quaternion: [
          -0.40731107135031586, 0.7681851345660746, -0.4435410776968716,
          0.21739503808196176,
        ],
      },
      {
        id: 99,
        position: [7.430529033288806, -4.655966369671717, 327.7457302943633],
        quaternion: [
          -0.7724838126553173, -0.3939279044636314, 0.2323829436419144,
          0.4405538931557814,
        ],
      },
      {
        id: 360,
        position: [8.233225500331997, 2.158219112701314, 356.9618096078292],
        quaternion: [
          -0.5620361368780165, -0.6554701596328945, 0.38342509337916697,
          0.32781007983471266,
        ],
      },
      {
        id: 103,
        position: [-0.28738075969368637, -8.953195199170523, 328.3816193328954],
        quaternion: [
          -0.8706514011131108, 0.04852902235754413, -0.02088200962043802,
          0.48905522530999496,
        ],
      },
      {
        id: 52,
        position: [5.6134588447361295, -6.228857827606959, 322.5747841316338],
        quaternion: [
          -0.8165820724532891, -0.29761402640654966, 0.17776501577264614,
          0.4615400409512958,
        ],
      },
      {
        id: 292,
        position: [5.971886893841514, -6.290125860167578, 349.40856945674113],
        quaternion: [
          -0.8225876765265082, -0.277356890932475, 0.1648259351840268,
          0.4682468158671266,
        ],
      },
      {
        id: 35,
        position: [-7.483208591120274, -4.091280422563429, 320.9034311555531],
        quaternion: [
          -0.7417147993969551, 0.460637875416588, -0.2637619286633541,
          0.4100018891115191,
        ],
      },
      {
        id: 165,
        position: [4.451562090329631, 7.75533556904962, 335.1140340992118],
        quaternion: [
          -0.26156487188071814, -0.8205695980699288, 0.4826857635716412,
          0.1589459221453659,
        ],
      },
      {
        id: 152,
        position: [-2.7624555576317533, -8.738044112713936, 333.96966019801397],
        quaternion: [
          -0.8584658977125067, 0.15591298142273552, -0.0857329897847863,
          0.4810169426861132,
        ],
      },
      {
        id: 330,
        position: [-2.0063020588666305, 8.62760697813627, 353.8562455774074],
        quaternion: [
          -0.07504197393053536, 0.8591437015348187, -0.505343824444344,
          0.029468989762518938,
        ],
      },
      {
        id: 76,
        position: [5.8782797790206445, -6.385573666261564, 325.29630854929724],
        quaternion: [
          -0.8188489696145624, -0.2889149892790872, 0.1735149935612925,
          0.46465798275770426,
        ],
      },
      {
        id: 333,
        position: [4.515345913236956, 7.621787317155068, 354.00532073721064],
        quaternion: [
          -0.26696899086605663, -0.8188489719843113, 0.48154098352479785,
          0.16227199444810722,
        ],
      },
      {
        id: 185,
        position: [-4.546595699824486, 7.82502671356454, 337.41928501028576],
        quaternion: [
          -0.18851891106000354, 0.8440896017729693, -0.4930987673644391,
          0.09395195567507494,
        ],
      },
      {
        id: 228,
        position: [-9.039314727679198, -2.233542659466604, 342.0361880873429],
        quaternion: [
          -0.662605032896353, 0.5684460282216407, -0.32859901631395577,
          0.3603420178899006,
        ],
      },
      {
        id: 101,
        position: [4.074142039489487, -7.862865792616304, 328.08053489976436],
        quaternion: [
          -0.8510942680602673, -0.17774005598092799, 0.10886703428871208,
          0.48186615176834613,
        ],
      },
      {
        id: 358,
        position: [6.168586570387433, 5.998165848488043, 356.70568222505466],
        quaternion: [
          -0.3697682084071524, -0.7783804387074038, 0.456743257427652,
          0.22086412448247905,
        ],
      },
      {
        id: 282,
        position: [-2.300580026708449, 8.551079926162146, 347.9972613820542],
        quaternion: [
          -0.07115700397564867, 0.8604060480721779, -0.5038340281499638,
          0.0280960015697658,
        ],
      },
      {
        id: 155,
        position: [-8.074828015967597, -4.491303550795855, 334.2548338612155],
        quaternion: [
          -0.7374958301956609, 0.4679388922596561, -0.26702393851921385,
          0.4072239062389461,
        ],
      },
      {
        id: 348,
        position: [-8.168250321641665, -1.8053850918714467, 355.80517415291763],
        quaternion: [
          -0.6650858292824657, 0.5635458553462507, -0.3258329163635886,
          0.36593690606949736,
        ],
      },
      {
        id: 91,
        position: [0.004795762107912438, 8.643091117109464, 326.80006627274065],
        quaternion: [
          -0.030656007222678774, -0.8626542032448049, 0.503886118717599,
          0.031412007400795465,
        ],
      },
      {
        id: 204,
        position: [-9.24513121295638, -2.0921860637734113, 339.64037243185203],
        quaternion: [
          -0.6660041813642854, 0.5628891532843063, -0.32614508881486426,
          0.36499809939520095,
        ],
      },
      {
        id: 77,
        position: [3.9831889813636323, -7.810809491713599, 325.47638419471934],
        quaternion: [
          -0.8512053788401038, -0.178618079496316, 0.11030604909315206,
          0.48101721408301196,
        ],
      },
      {
        id: 334,
        position: [6.191398009355652, 6.0688479248545235, 354.0797760730178],
        quaternion: [
          -0.3693748790277154, -0.7783557450842543, 0.4574608501790801,
          0.22012292790854227,
        ],
      },
      {
        id: 92,
        position: [2.319347600789804, 8.307063616093316, 326.88926525834455],
        quaternion: [
          -0.14447800279463804, -0.8498750164391327, 0.49777800962852015,
          0.09516100184069927,
        ],
      },
      {
        id: 349,
        position: [-8.393462207671064, 0.39444001268434903, 355.92388153417795],
        quaternion: [
          -0.5865257357461681, 0.6433007101667201, -0.3728818320014844,
          0.3211088553273278,
        ],
      },
      {
        id: 6,
        position: [1.7010704554629754, -7.9068852412870285, 317.69635106449425],
        quaternion: [
          -0.8670000837253352, -0.09175300886049675, 0.05981300577608244,
          0.48612204694432226,
        ],
      },
      {
        id: 263,
        position: [7.61964342188557, 4.3006867438499725, 345.64988286038624],
        quaternion: [
          -0.47322905835908435, -0.7211670889350521, 0.42375805225827,
          0.2764080340869173,
        ],
      },
      {
        id: 304,
        position: [-6.2599034702585925, 6.449907539484883, 350.50301319562567],
        quaternion: [
          -0.289880055903954, 0.8158061573298643, -0.4770520920004589,
          0.15116602915267388,
        ],
      },
      {
        id: 47,
        position: [6.991125429561862, 3.9960462154791614, 321.8976966445982],
        quaternion: [
          -0.4586529612417624, -0.7317899381604598, 0.42840996379743174,
          0.2656799775488473,
        ],
      },
      {
        id: 177,
        position: [-4.9306513365030185, -7.74042588218434, 336.7712471731114],
        quaternion: [
          -0.830505850191292, 0.2709319511286218, -0.15244397250177763,
          0.46218691662957606,
        ],
      },
      {
        id: 69,
        position: [4.329905088347502, 7.228149616988688, 324.3620034701132],
        quaternion: [
          -0.25331783128455426, -0.8243884509385137, 0.4815446792802749,
          0.15597589611650037,
        ],
      },
      {
        id: 326,
        position: [-8.21514696184559, 2.6588671485704864, 353.39986077665577],
        quaternion: [
          -0.49265511769064146, 0.7152391708638636, -0.41740309971364714,
          0.2673920638774315,
        ],
      },
      {
        id: 210,
        position: [-2.368443702049017, 8.769759156390279, 340.1516372722315],
        quaternion: [
          -0.06834199725015729, 0.8600579653942784, -0.5049679796818562,
          0.025238998984471038,
        ],
      },
      {
        id: 83,
        position: [-7.944212450284141, -4.325141211099883, 326.2015038268016],
        quaternion: [
          -0.7375819450556791, 0.46805596513334236, -0.26762998006357447,
          0.4065349697161949,
        ],
      },
      {
        id: 337,
        position: [8.556507871794906, 0.03884850505224691, 354.1895528356541],
        quaternion: [
          -0.6446883288362597, -0.5753582934730797, 0.33792417236502653,
          0.37302419026849726,
        ],
      },
      {
        id: 80,
        position: [-2.5952635162345885, -8.462039994349453, 325.8981497753124],
        quaternion: [
          -0.8592623470591395, 0.1574720636035305, -0.08513803438755703,
          0.4791891935462315,
        ],
      },
      {
        id: 303,
        position: [-7.617343089204184, 4.702649334823292, 350.41449833620794],
        quaternion: [
          -0.3962289289525934, 0.7715398616559714, -0.45044391923135857,
          0.21172896203509495,
        ],
      },
      {
        id: 112,
        position: [-6.355728238669158, 6.298735554842651, 329.28988572595443],
        quaternion: [
          -0.2989689074929055, 0.8133357483372851, -0.4740698533130917,
          0.15607795170628963,
        ],
      },
      {
        id: 369,
        position: [-4.21661137911714, -6.969471567483964, 358.40451418536236],
        quaternion: [
          -0.8293142491998469, 0.27266008193136765, -0.15144904550877908,
          0.4636351393172803,
        ],
      },
      {
        id: 126,
        position: [1.95964403237587, -8.831161124202538, 330.982823626767],
        quaternion: [
          -0.8690920767399648, -0.06528100576424781, 0.04360400385019011,
          0.4883810431235597,
        ],
      },
      {
        id: 115,
        position: [-0.09484550249030832, 8.808294699179896, 329.5528679247302],
        quaternion: [
          -0.03561500783656692, -0.8616501895936511, 0.505220111166372,
          0.03229800710670892,
        ],
      },
      {
        id: 372,
        position: [-8.080793615833159, -1.726445037111933, 358.5693058350161],
        quaternion: [
          -0.66565113839288, 0.5624291169324002, -0.3256250676994124,
          0.3668110762622316,
        ],
      },
      {
        id: 98,
        position: [8.314111315427226, -2.617281589417914, 327.65058966570996],
        quaternion: [
          -0.7121828181658623, -0.4928748741594497, 0.28929492613737356,
          0.4076498959190457,
        ],
      },
      {
        id: 170,
        position: [8.548402191825147, -2.5281507621251365, 335.73817325815025],
        quaternion: [
          -0.7155529518754878, -0.4871339672378061, 0.28652298072989757,
          0.4105959723853688,
        ],
      },
      {
        id: 37,
        position: [-8.38516104706531, 0.06504677016648586, 321.0991700987636],
        quaternion: [
          -0.6002612618010051, 0.6319932756407673, -0.3634061584978136,
          0.3289481434691193,
        ],
      },
      {
        id: 153,
        position: [-4.863981834579832, -7.956318002988098, 334.07725638169063],
        quaternion: [
          -0.8326920173641269, 0.26199900546346533, -0.14757000307727733,
          0.46497700969616573,
        ],
      },
      {
        id: 45,
        position: [4.011966267497627, 6.93032116953016, 321.7031472346414],
        quaternion: [
          -0.24787286712109263, -0.8267395568040574, 0.482479741353777,
          0.14924491999325246,
        ],
      },
      {
        id: 329,
        position: [-4.072757136090651, 7.8285975093288585, 353.70829487431723],
        quaternion: [
          -0.1842470825634393, 0.8439233781726996, -0.4954412220134544,
          0.09156604103189676,
        ],
      },
      {
        id: 72,
        position: [8.323311635790105, 2.039521569168194, 324.7102224367294],
        quaternion: [
          -0.5583000642262849, -0.6588640757950688, 0.3869010445087118,
          0.3232750371892391,
        ],
      },
      {
        id: 32,
        position: [-2.661633329254279, -7.9746740618944045, 320.4843356998095],
        quaternion: [
          -0.8619042503134069, 0.1427760414648812, -0.07640302218889253,
          0.4805191395519083,
        ],
      },
      {
        id: 218,
        position: [8.635385087630226, -2.44148191151244, 340.72639330859266],
        quaternion: [
          -0.7213927703652079, -0.478938847543492, 0.28269291001278324,
          0.412667868638966,
        ],
      },
      {
        id: 82,
        position: [-6.541221491289974, -6.230766081663447, 326.1505718849745],
        quaternion: [
          -0.7918511187844075, 0.37032205555146025, -0.21024303153824417,
          0.4377570656672857,
        ],
      },
      {
        id: 339,
        position: [7.315999233852111, -4.275809449094339, 354.61550876606094],
        quaternion: [
          -0.7744198569515005, -0.3897759280017666, 0.22917695766712382,
          0.44252291825850165,
        ],
      },
      {
        id: 209,
        position: [-4.70269200991406, 7.9329020969891815, 340.03227721978607],
        quaternion: [
          -0.18418003820032527, 0.844308175115866, -0.49503910267483336,
          0.09032001873305123,
        ],
      },
      {
        id: 137,
        position: [-4.521374204718512, 7.738803429201596, 332.0750647921719],
        quaternion: [
          -0.18625209679440172, 0.8438474385438305, -0.49456425702288564,
          0.09294004830053743,
        ],
      },
      {
        id: 264,
        position: [8.510495320101569, 2.0992197905793724, 345.7539476798876],
        quaternion: [
          -0.5664022221005149, -0.6521762557346645, 0.3831371502376845,
          0.3272011283037677,
        ],
      },
      {
        id: 7,
        position: [
          -0.43883696749247214, -8.216466017732671, 317.84752350014776,
        ],
        quaternion: [
          -0.8727071156738451, 0.022669003004685877, -0.006587000873080676,
          0.48767306463911836,
        ],
      },
      {
        id: 188,
        position: [2.3023159693322413, 8.710711929822082, 337.63125520404947],
        quaternion: [
          -0.1521710115088462, -0.8480340641376667, 0.4980900376710491,
          0.09792100740586399,
        ],
      },
      {
        id: 54,
        position: [1.952806853429415, -8.382459730566268, 322.8804075986827],
        quaternion: [
          -0.8682690736591758, -0.07565900641849425, 0.05071700430255188,
          0.48766004137039753,
        ],
      },
      {
        id: 325,
        position: [-8.626042089508786, 0.40733159548406306, 353.3135851252333],
        quaternion: [
          -0.5836910896076793, 0.6450480990271468, -0.3759960577225433,
          0.31913104899268335,
        ],
      },
      {
        id: 68,
        position: [2.218284776016908, 8.155473379265771, 324.2479344124256],
        quaternion: [
          -0.14158395339906524, -0.8509337199237216, 0.49711183638063716,
          0.09351796921950069,
        ],
      },
      {
        id: 71,
        position: [7.419965168489114, 4.133283462816607, 324.57525089857194],
        quaternion: [
          -0.46320314993448347, -0.7280412356600698, 0.42602513790031227,
          0.2718480879946578,
        ],
      },
      {
        id: 328,
        position: [-5.9101553733502215, 6.472800597511409, 353.5849938247913],
        quaternion: [
          -0.2918780936149516, 0.8147712613240728, -0.4769471529727157,
          0.15322004914273388,
        ],
      },
      {
        id: 332,
        position: [2.4132601464813987, 8.543809313772954, 353.94228328872583],
        quaternion: [
          -0.15338193854554466, -0.8471306605861562, 0.49912880001759763,
          0.09855996051067845,
        ],
      },
      {
        id: 75,
        position: [7.33682334869512, -4.6269246765979375, 325.15871997095945],
        quaternion: [
          -0.7724790436288451, -0.3942860222688809, 0.2338260132062598,
          0.4394770248212236,
        ],
      },
      {
        id: 118,
        position: [6.200931427619949, 6.158218609373108, 329.83395325542483],
        quaternion: [
          -0.3665840220117216, -0.7800280468371755, 0.45793802749711865,
          0.21786501308181405,
        ],
      },
      {
        id: 375,
        position: [-7.061691698038473, 4.4952003096215805, 358.7914402733865],
        quaternion: [
          -0.4011580614243242, 0.7684961176702133, -0.44943206881592007,
          0.21563103301688724,
        ],
      },
      {
        id: 245,
        position: [4.213883862281044, -7.953177157862655, 343.98317432328673],
        quaternion: [
          -0.8539408562006416, -0.16679697191222628, 0.10085198301703176,
          0.48248591875179064,
        ],
      },
      {
        id: 363,
        position: [7.263081217439378, -4.30805784216065, 357.62930193327395],
        quaternion: [
          -0.7739851815556603, -0.3895850913859596, 0.2291060537419861,
          0.44348710402989094,
        ],
      },
      {
        id: 106,
        position: [-6.66662645472627, -6.25425729704925, 328.7996373126925],
        quaternion: [
          -0.7901158109533583, 0.37339591065962485, -0.2116759493534659,
          0.4375908953000458,
        ],
      },
      {
        id: 9,
        position: [-4.2840373662579765, -7.220517700736266, 318.10229052667745],
        quaternion: [
          -0.8388392524340313, 0.24567707393222718, -0.1366770411305699,
          0.46616614028456316,
        ],
      },
      {
        id: 127,
        position: [
          -0.37856447133203375, -9.058400723980668, 331.13669839490865,
        ],
        quaternion: [
          -0.8708419993616728, 0.04688899996563036, -0.02113399998450878,
          0.48886499964166197,
        ],
      },
      {
        id: 278,
        position: [-8.669631187160633, 2.4928467673721784, 347.5518988289935],
        quaternion: [
          -0.4927299148747555, 0.7170318761237913, -0.41609492811440624,
          0.2644759543084769,
        ],
      },
      {
        id: 21,
        position: [4.06464129479204, 6.8713114244957865, 319.10037256606665],
        quaternion: [
          -0.2435059617463122, -0.8274288700146581, 0.484166923939561,
          0.14713497688575908,
        ],
      },
      {
        id: 39,
        position: [-7.293459855950218, 3.976952276679185, 321.21075724586734],
        quaternion: [
          -0.41084587852131405, 0.7654747736648352, -0.44282686906519214,
          0.22171693444285737,
        ],
      },
      {
        id: 296,
        position: [-2.5219906228062854, -8.524292259435356, 349.9095360276616],
        quaternion: [
          -0.8563022121784418, 0.1669350413639209, -0.09418902333858295,
          0.47959111883526034,
        ],
      },
      {
        id: 104,
        position: [-2.561778479916768, -8.656045334465489, 328.516181535301],
        quaternion: [
          -0.8584390212510875, 0.15963800395191868, -0.08551800211704094,
          0.47988001187966983,
        ],
      },
      {
        id: 361,
        position: [8.441539992094363, -0.06637452045150383, 357.16836784360635],
        quaternion: [
          -0.6440378122330573, -0.5765128319197262, 0.33710790171738547,
          0.37310389122288223,
        ],
      },
      {
        id: 132,
        position: [-8.899200237929989, -2.3105109501956136, 331.65804485607066],
        quaternion: [
          -0.6668910478734424, 0.5635850404575172, -0.32205102311875555,
          0.3659390262692999,
        ],
      },
      {
        id: 5,
        position: [3.626207014250369, -7.042636878114067, 317.5391840796203],
        quaternion: [
          -0.8462901800647576, -0.20299204319052014, 0.12450102649002398,
          0.47653610139235886,
        ],
      },
      {
        id: 262,
        position: [6.2992373463685425, 6.22872016861929, 345.56864852600023],
        quaternion: [
          -0.37172986275940456, -0.7770857131042818, 0.4572378311903387,
          0.2211069183685569,
        ],
      },
      {
        id: 173,
        position: [4.143955585207074, -7.926968249056017, 336.1573949654524],
        quaternion: [
          -0.8519660738186037, -0.1763670152813207, 0.10680100925377384,
          0.48129204170155077,
        ],
      },
      {
        id: 160,
        position: [-6.413280055651953, 6.52564743486154, 334.6398108322079],
        quaternion: [
          -0.2997640023954141, 0.8130320064969389, -0.47423700378962796,
          0.15562700124361536,
        ],
      },
      {
        id: 33,
        position: [-4.604042868595769, -7.089017196334069, 320.6596106300463],
        quaternion: [
          -0.837539782995983, 0.2489399355004179, -0.13949496385727805,
          0.4659368792771681,
        ],
      },
      {
        id: 269,
        position: [3.991595116611606, -7.964444643668685, 346.8004946703814],
        quaternion: [
          -0.8530512606349241, -0.16735705113302604, 0.10270303137911872,
          0.4834741477170876,
        ],
      },
      {
        id: 67,
        position: [0.022396049838446785, 8.468497757514399, 324.2008683918169],
        quaternion: [
          -0.029540992938256027, -0.8629497937127395, 0.5035138796355251,
          0.030310992754188363,
        ],
      },
      {
        id: 324,
        position: [-8.372441864674698, -1.8290208883576682, 353.12608142954633],
        quaternion: [
          -0.6631060200914496, 0.5650180171194812, -0.32899100996809877,
          0.3644310110418954,
        ],
      },
      {
        id: 321,
        position: [
          -4.5120477596452275, -7.3612396522760974, 352.97463316224304,
        ],
        quaternion: [
          -0.8293381426523928, 0.2739010471130384, -0.15494402665153695,
          0.46170207941622726,
        ],
      },
      {
        id: 64,
        position: [-6.192716470980287, 6.067030600381273, 323.98490059526006],
        quaternion: [
          -0.3032759365933484, 0.812679830091014, -0.4718119013571166,
          0.15801496696341927,
        ],
      },
      {
        id: 234,
        position: [-2.3508181970464896, 8.720022567464184, 342.53604553446354],
        quaternion: [
          -0.06651598412523062, 0.8603597946656956, -0.5047288795409153,
          0.024604994127748196,
        ],
      },
      {
        id: 140,
        position: [2.264217946847914, 8.67040598580411, 332.28247444042205],
        quaternion: [
          -0.14800496812136135, -0.8486928172009225, 0.4987698925704631,
          0.09509797951694349,
        ],
      },
      {
        id: 267,
        position: [7.528060288918738, -4.724110118334352, 346.40189909303365],
        quaternion: [
          -0.7781019793223293, -0.382508989835015, 0.22761999395111254,
          0.4432079882219695,
        ],
      },
      {
        id: 22,
        position: [5.598730665691107, 5.515337390753103, 319.22303258433806],
        quaternion: [
          -0.35430204138584676, -0.786242091840551, 0.46118205387044825,
          0.20881702439181365,
        ],
      },
      {
        id: 149,
        position: [4.091282579881437, -7.9205611231090245, 333.4562041549869],
        quaternion: [
          -0.8505662969710216, -0.18146506335763057, 0.11078003867830336,
          0.480973167929406,
        ],
      },
      {
        id: 261,
        position: [4.411918512089414, 7.693636791593903, 345.4756019082505],
        quaternion: [
          -0.2660250854170773, -0.8182682627349347, 0.4827431550023349,
          0.16317705239395705,
        ],
      },
      {
        id: 4,
        position: [5.364306672918232, -5.834697534781867, 317.394741498104],
        quaternion: [
          -0.8106837964446204, -0.31179892171010676, 0.18692995306357707,
          0.4589448847630844,
        ],
      },
      {
        id: 320,
        position: [-2.2973086231421362, -8.14826655698289, 352.96253328609805],
        quaternion: [
          -0.8568177159444106, 0.16625994488084717, -0.09153996965230812,
          0.479417841061506,
        ],
      },
      {
        id: 193,
        position: [8.861385221757274, -0.14484252107807194, 338.2197447049295],
        quaternion: [
          -0.6456050844538662, -0.5747780751887367, 0.33703004408808257,
          0.37314204881202057,
        ],
      },
      {
        id: 66,
        position: [-2.2814293610021252, 8.23691855720105, 324.1521772326582],
        quaternion: [
          -0.08353101592109669, 0.8603511639838078, -0.501660095616925,
          0.03399400647929225,
        ],
      },
      {
        id: 323,
        position: [-7.618931525389115, -3.9710445382534942, 353.0385455505837],
        quaternion: [
          -0.7299473466232443, 0.47797522697160916, -0.27732313168983225,
          0.40225419101467164,
        ],
      },
      {
        id: 124,
        position: [6.054441852417426, -6.440711320738259, 330.6665936472642],
        quaternion: [
          -0.8205662531131364, -0.28700108852879996, 0.17189205302208868,
          0.46341614294606065,
        ],
      },
      {
        id: 272,
        position: [-2.6579131835584704, -8.75283344577349, 347.1698714020101],
        quaternion: [
          -0.855777061345953, 0.1689030121077284, -0.09244700662701769,
          0.4801780344213235,
        ],
      },
      {
        id: 232,
        position: [-6.465384262070771, 6.4347493669994975, 342.11525401013245],
        quaternion: [
          -0.28777109316144606, 0.817177264548516, -0.4769811544152805,
          0.14798004790625457,
        ],
      },
      {
        id: 105,
        position: [-4.6870559325760865, -7.725554598094831, 328.6805113601694],
        quaternion: [
          -0.8313637856786006, 0.2689769306591011, -0.1497529613944403,
          0.4626658807270647,
        ],
      },
      {
        id: 362,
        position: [8.114404572159486, -2.2591366660503027, 357.37826381367177],
        quaternion: [
          -0.7151568308532719, -0.4872228847635186, 0.2853199325169935,
          0.4120159025512463,
        ],
      },
      {
        id: 195,
        position: [7.615522382549372, -4.613890049651175, 338.5257381602669],
        quaternion: [
          -0.776205399720269, -0.38587419871252965, 0.22816111749547646,
          0.4433382283046162,
        ],
      },
      {
        id: 79,
        position: [-0.37980058430353303, -8.808246790902956, 325.7572107525932],
        quaternion: [
          -0.871338924250154, 0.04657499595100291, -0.0199179982684289,
          0.4880599575705095,
        ],
      },
      {
        id: 256,
        position: [-6.407837117819852, 6.497568971003773, 344.9315097326867],
        quaternion: [
          -0.2914259790744498, 0.8152439414622261, -0.477159965738007,
          0.15088598916578283,
        ],
      },
      {
        id: 283,
        position: [-0.04951466935109039, 8.910012026689685, 348.0985302821641],
        quaternion: [
          -0.04326601015578849, -0.8615642022341273, 0.5044891184182402,
          0.03641300854719009,
        ],
      },
      {
        id: 108,
        position: [-8.800425431816478, -2.046104808467625, 328.991784004963],
        quaternion: [
          -0.6678247150315749, 0.56156176037519, -0.3242248616495525,
          0.36542684406819653,
        ],
      },
      {
        id: 365,
        position: [4.148340129296152, -7.257044582983042, 357.97696938205866],
        quaternion: [
          -0.8514236654923103, -0.17538793109351547, 0.10647995816610903,
          0.482678810364945,
        ],
      },
      {
        id: 244,
        position: [6.107080778806079, -6.536268270392284, 343.7504113714127],
        quaternion: [
          -0.8238486242727987, -0.27747887345204264, 0.16410692515683842,
          0.46620578738060536,
        ],
      },
      {
        id: 376,
        position: [-5.656168050881234, 6.226425249488969, 358.95373272767193],
        quaternion: [
          -0.29623599983929194, 0.8130959995588954, -0.4764129997415459,
          0.15540199991569442,
        ],
      },
      {
        id: 119,
        position: [7.5764250654340515, 4.192652875524992, 329.93960628701404],
        quaternion: [
          -0.46581897948836043, -0.7255089680533016, 0.42649698121984564,
          0.2734039879610658,
        ],
      },
      {
        id: 174,
        position: [1.917625411903039, -8.78194790856341, 336.3302211238539],
        quaternion: [
          -0.8689491209294853, -0.0636080088521682, 0.04137000575736068,
          0.48905006805988016,
        ],
      },
      {
        id: 238,
        position: [6.314078117379125, 6.352519929108567, 343.0421321032132],
        quaternion: [
          -0.3764638999504026, -0.7745767941473367, 0.4573068784654542,
          0.22175694106555055,
        ],
      },
      {
        id: 53,
        position: [3.902233180465089, -7.369656646744261, 322.74145702682915],
        quaternion: [
          -0.8494412214247214, -0.18895804925589008, 0.11561803013827146,
          0.47893312484399286,
        ],
      },
      {
        id: 167,
        position: [7.735821110379959, 4.25380216252848, 335.2730442514178],
        quaternion: [
          -0.46772891272132533, -0.7240318648949426, 0.42568092056752194,
          0.2753239486242807,
        ],
      },
      {
        id: 252,
        position: [-8.962690221665383, -2.2841173349859076, 344.67688121930735],
        quaternion: [
          -0.6610089521419621, 0.5698329587432407, -0.32962797613444095,
          0.36014297392510947,
        ],
      },
      {
        id: 93,
        position: [4.361844842043482, 7.429346904895709, 326.9732690280593],
        quaternion: [
          -0.2550469936845265, -0.8229229796227814, 0.48298098804042494,
          0.15645499612586145,
        ],
      },
      {
        id: 347,
        position: [-7.320509071688207, -3.9591523569161424, 355.70312912441443],
        quaternion: [
          -0.7333511317091418, 0.4728150849171241, -0.2726800489730685,
          0.40532407279580474,
        ],
      },
      {
        id: 34,
        position: [-6.285219722892606, -5.910311092224006, 320.77744984630823],
        quaternion: [
          -0.7964885176241172, 0.3588512332112123, -0.2041971327041862,
          0.4417412870800253,
        ],
      },
      {
        id: 291,
        position: [7.432295187460767, -4.656160860310308, 349.2776255334545],
        quaternion: [
          -0.7776603010413039, -0.38315214832263156, 0.2259670874744751,
          0.4442721719829002,
        ],
      },
      {
        id: 288,
        position: [8.400163038497993, 2.218394433972044, 348.5598247497466],
        quaternion: [
          -0.5683970401134858, -0.6503700458985668, 0.3815790269291776,
          0.3291520232292465,
        ],
      },
      {
        id: 31,
        position: [
          -0.41178362132504276, -8.132458874104856, 320.34975887922917,
        ],
        quaternion: [
          -0.8721712608581775, 0.031011009275099654, -0.010716003205055235,
          0.4880991459858394,
        ],
      },
      {
        id: 150,
        position: [1.882074246626157, -8.763451029407179, 333.63876371276086],
        quaternion: [
          -0.8679409192801963, -0.07027299346450651, 0.04590799573048773,
          0.4895229544736331,
        ],
      },
      {
        id: 277,
        position: [-9.055225571315646, 0.115317862866533, 347.5122413419821],
        quaternion: [
          -0.5826399246515532, 0.648161916178086, -0.3757349514090199,
          0.3150239592603167,
        ],
      },
      {
        id: 23,
        position: [6.808965637660421, 3.5889738791630705, 319.29753262003226],
        quaternion: [
          -0.45249093795851925, -0.7347178992621011, 0.43155894082852614,
          0.2630559639321362,
        ],
      },
      {
        id: 280,
        position: [-6.461060728090061, 6.34106878146649, 347.722575861452],
        quaternion: [
          -0.29046103929967176, 0.8164911104720712, -0.4757400643681108,
          0.15048602036091044,
        ],
      },
      {
        id: 318,
        position: [2.146815438228284, -8.30285454745129, 352.82018362503885],
        quaternion: [
          -0.8691168366377728, -0.0569749892907826, 0.0383169927978046,
          0.48981790793212027,
        ],
      },
      {
        id: 215,
        position: [7.78198693141772, 4.4531516650804335, 340.62893347140715],
        quaternion: [
          -0.4749869134186808, -0.7193308688793023, 0.422891922914633,
          0.27948694905470434,
        ],
      },
      {
        id: 70,
        position: [6.041154558505426, 5.92530132602532, 324.4706317221516],
        quaternion: [
          -0.3617449623040064, -0.7829389184130714, 0.45762895231231987,
          0.2161549774753556,
        ],
      },
      {
        id: 327,
        position: [-7.347710890197547, 4.7582016402735405, 353.4851526683268],
        quaternion: [
          -0.3979571270380831, 0.7703602459186739, -0.4500681436732511,
          0.21357406817829958,
        ],
      },
      {
        id: 198,
        position: [1.888204289851, -8.727948523741645, 339.0516441077795],
        quaternion: [
          -0.8695451617101984, -0.060063011169979294, 0.038690007195220005,
          0.4886580908762423,
        ],
      },
      {
        id: 203,
        position: [-8.332049374023569, -4.334915965857412, 339.6147576679736],
        quaternion: [
          -0.733338705601969, 0.47298981011875185, -0.27329389028646306,
          0.4047288375220455,
        ],
      },
      {
        id: 250,
        position: [-6.695533595540084, -6.3641983256167975, 344.65867612359045],
        quaternion: [
          -0.7859920916443253, 0.3812290444501655, -0.2179550254129036,
          0.43517405073999177,
        ],
      },
      {
        id: 200,
        position: [-2.814001165082857, -8.701516051765623, 339.39885605619065],
        quaternion: [
          -0.857245095348377, 0.16426701827084653, -0.09282801032493526,
          0.47909305328784646,
        ],
      },
      {
        id: 28,
        position: [5.480808758690955, -5.991968128037087, 319.96975803051697],
        quaternion: [
          -0.8140840800305746, -0.3022300297114801, 0.1827020179609795,
          0.4610250453222219,
        ],
      },
      {
        id: 285,
        position: [4.431254887461222, 7.6814506712391335, 348.16316536365883],
        quaternion: [
          -0.27068414951364933, -0.8181914519318552, 0.47982726503482226,
          0.16449509085962877,
        ],
      },
      {
        id: 158,
        position: [-8.987863065170615, 2.493979509057233, 334.53144531684273],
        quaternion: [
          -0.5022762220197007, 0.7108473142137753, -0.4118401820445204,
          0.26981911926736224,
        ],
      },
      {
        id: 319,
        position: [-0.08198654299232544, -8.546685492097481, 352.9590703251615],
        quaternion: [
          -0.870240606046518, 0.05525897498454398, -0.026467988018076875,
          0.48880177872192887,
        ],
      },
      {
        id: 142,
        position: [6.353036521487766, 6.212696888661938, 332.49662039663167],
        quaternion: [
          -0.3662429211867293, -0.7795588322436346, 0.4588529012576194,
          0.2181929530461907,
        ],
      },
      {
        id: 121,
        position: [8.763051272483665, -0.17951167707030358, 330.2329002368731],
        quaternion: [
          -0.6416488851727716, -0.5792358963419806, 0.3417499388416326,
          0.36875393400909257,
        ],
      },
      {
        id: 331,
        position: [0.21595704529206827, 8.927408402219356, 353.9333659611047],
        quaternion: [
          -0.040289010131095905, -0.8604372163659999, 0.5067151274188554,
          0.035558008941435834,
        ],
      },
      {
        id: 183,
        position: [-7.981253809594861, 4.693152460009699, 337.3102819375479],
        quaternion: [
          -0.4041589176788993, 0.7687958434078347, -0.4464429090662853,
          0.21516795617351933,
        ],
      },
      {
        id: 175,
        position: [-0.33535067583119016, -8.97758774748857, 336.48651751502337],
        quaternion: [
          -0.8708211196878399, 0.04906100674306788, -0.024093003311402836,
          0.4885520671478221,
        ],
      },
      {
        id: 176,
        position: [-2.736295124640698, -8.707039979003184, 336.6250773310459],
        quaternion: [
          -0.8580878810823283, 0.16117397766378647, -0.08881798769120444,
          0.4793949335632975,
        ],
      },
      {
        id: 240,
        position: [8.62589029016246, 2.1854602784615693, 343.1654640025081],
        quaternion: [
          -0.5660520328918694, -0.6516410378652328, 0.3847660223577923,
          0.32696201899894606,
        ],
      },
      {
        id: 94,
        position: [6.156246606709871, 6.1106238632372225, 327.0935018782807],
        quaternion: [
          -0.36326887458035295, -0.7820607299912334, 0.4582178417989428,
          0.21552992558765946,
        ],
      },
      {
        id: 351,
        position: [-7.216327761429142, 4.597090442304449, 356.127938955759],
        quaternion: [
          -0.4010490549952591, 0.7693551055005189, -0.4486890615280622,
          0.2143140293885634,
        ],
      },
      {
        id: 8,
        position: [-2.3885442571599, -7.979009546232419, 317.9796292445386],
        quaternion: [
          -0.8635385543662946, 0.13371793099414409, -0.07127496321817273,
          0.4809787517883338,
        ],
      },
      {
        id: 368,
        position: [-2.1934629315707355, -7.921391190066402, 358.2848057351599],
        quaternion: [
          -0.8570174925061009, 0.16245690379906097, -0.08763294810702593,
          0.48109171511537113,
        ],
      },
      {
        id: 251,
        position: [-8.102614753020703, -4.386077875431859, 344.6370421708896],
        quaternion: [
          -0.7298257496847722, 0.4796708354827649, -0.2763409052207508,
          0.401130862420361,
        ],
      },
      {
        id: 169,
        position: [8.883504852782352, -0.23880678151825308, 335.5752004153162],
        quaternion: [
          -0.6429751700634186, -0.577299152692471, 0.33936808976100513,
          0.3716690983044454,
        ],
      },
      {
        id: 164,
        position: [2.323065176108443, 8.65089574306316, 334.9973669981219],
        quaternion: [
          -0.15262902345206178, -0.8477091302539088, 0.4985710766074461,
          0.09757301499248519,
        ],
      },
      {
        id: 148,
        position: [6.089295458883427, -6.541385835592166, 333.3311314556954],
        quaternion: [
          -0.8180010786434455, -0.29154002802895124, 0.17496901682169708,
          0.4639660446061617,
        ],
      },
      {
        id: 273,
        position: [-4.903545953155842, -7.784476122090027, 347.277607881495],
        quaternion: [
          -0.8278059128477704, 0.27707497082927157, -0.15579898359732808,
          0.46226995133175985,
        ],
      },
      {
        id: 16,
        position: [-5.788978168714337, 5.529478310056291, 318.7136496342629],
        quaternion: [
          -0.31399404365585093, 0.8092341125110635, -0.4674960649978525,
          0.1673180232628957,
        ],
      },
      {
        id: 133,
        position: [-9.177184320509383, 0.10153567398405627, 331.73963650937446],
        quaternion: [
          -0.5869302228600891, 0.645037244923591, -0.3708711408214678,
          0.3192091212051628,
        ],
      },
      {
        id: 248,
        position: [-2.713477512391968, -8.674547683899931, 344.65561801775004],
        quaternion: [
          -0.8552869815262291, 0.17240499627613834, -0.0944459979600137,
          0.4794169896448328,
        ],
      },
      {
        id: 271,
        position: [-0.320694887025752, -9.119543257382304, 347.0905603522037],
        quaternion: [
          -0.8693571980139462, 0.05869101336808298, -0.02812300640559196,
          0.48988011158025063,
        ],
      },
      {
        id: 286,
        position: [6.248900033673223, 6.266620117672872, 348.24616291120975],
        quaternion: [
          -0.37705409483854313, -0.7753631950232521, 0.45456811433525934,
          0.22362905624830542,
        ],
      },
      {
        id: 29,
        position: [3.787571893416178, -7.1308549689767915, 320.13064153520696],
        quaternion: [
          -0.8478081648673352, -0.19576403806886583, 0.12111502355239312,
          0.4777330929014195,
        ],
      },
      {
        id: 36,
        position: [-8.275511861750772, -2.134804820852345, 321.0086311402446],
        quaternion: [
          -0.6773324975947129, 0.5500434040832097, -0.31567923191020264,
          0.37285327391247053,
        ],
      },
      {
        id: 138,
        position: [-2.3242047981673615, 8.584809273257775, 332.1282573125506],
        quaternion: [
          -0.07575399497080608, 0.8594319429436044, -0.5046409664976443,
          0.0312339979264218,
        ],
      },
      {
        id: 95,
        position: [7.507034450889776, 4.173616071860536, 327.20470305820095],
        quaternion: [
          -0.4636731976050398, -0.7279983102532901, 0.42629518167553515,
          0.2707361153804471,
        ],
      },
      {
        id: 352,
        position: [-5.791722343759972, 6.259111663978786, 356.2459351254423],
        quaternion: [
          -0.29644102815552553, 0.8134930772643559, -0.47593704520378877,
          0.15438801466354274,
        ],
      },
      {
        id: 239,
        position: [7.771767272352633, 4.458251941156384, 343.0731222072141],
        quaternion: [
          -0.4754630580488129, -0.7192260878095992, 0.424444051819953,
          0.27657903376725973,
        ],
      },
      {
        id: 182,
        position: [-8.896906669289535, 2.547597867423285, 337.19915330948197],
        quaternion: [
          -0.5001921911772435, 0.7122622722320344, -0.41216615753302677,
          0.26946110299007425,
        ],
      },
      {
        id: 194,
        position: [8.565498955929826, -2.4793197973754175, 338.35425188994964],
        quaternion: [
          -0.7178768294310499, -0.48352488511353386, 0.28445093241389763,
          0.41224290205027364,
        ],
      },
      {
        id: 258,
        position: [-2.355691824434441, 8.725595254721185, 345.1230151469171],
        quaternion: [
          -0.06678401690053241, 0.8598872176052366, -0.5055471279350362,
          0.02357900596696295,
        ],
      },
      {
        id: 125,
        position: [4.130925781036325, -7.910481920247719, 330.83592355581544],
        quaternion: [
          -0.8521240078561574, -0.17798000164088665, 0.1089680010046305,
          0.479931004424724,
        ],
      },
      {
        id: 314,
        position: [8.437966976763022, -2.2177841672025735, 351.9158588896759],
        quaternion: [
          -0.720090966991751, -0.48166797792082205, 0.28303798702582206,
          0.41152698113601516,
        ],
      },
      {
        id: 374,
        position: [-7.930317876825541, 2.55682736353603, 358.67869432592903],
        quaternion: [
          -0.4975138795815064, 0.7118598277011122, -0.4153778994617377,
          0.2705489345162085,
        ],
      },
      {
        id: 117,
        position: [4.359514322891982, 7.582147029182195, 329.7087431687054],
        quaternion: [
          -0.26136489845701244, -0.8210356810190791, 0.48198981274193325,
          0.15897993823463674,
        ],
      },
      {
        id: 107,
        position: [-7.9526300765504345, -4.2344020098740955, 328.8781809340235],
        quaternion: [
          -0.7346749190200942, 0.4725239479158145, -0.2706729701649382,
          0.4046109554015576,
        ],
      },
      {
        id: 364,
        position: [5.897477474891687, -5.984113090788668, 357.82566314165587],
        quaternion: [
          -0.819997045005539, -0.2842860156030384, 0.16879500926431434,
          0.46722002564337184,
        ],
      },
      {
        id: 192,
        position: [8.527944748965496, 2.2303166626320916, 338.0651242138573],
        quaternion: [
          -0.561801016367792, -0.6554850190972287, 0.38438701119892354,
          0.3270560095286135,
        ],
      },
      {
        id: 253,
        position: [-9.282810900474772, 0.17422459113557665, 344.63066088330265],
        quaternion: [
          -0.5817026511710575, 0.6489516108439531, -0.37687677399874964,
          0.31376381184562513,
        ],
      },
      {
        id: 196,
        position: [6.086827519446118, -6.475417939319257, 338.64230502916115],
        quaternion: [
          -0.8220767795737054, -0.27992692494222393, 0.16727195514879126,
          0.4667448748500799,
        ],
      },
      {
        id: 211,
        position: [-0.06565817367026838, 9.114070631760484, 340.2875923182277],
        quaternion: [
          -0.04467597314778247, -0.8607474826530455, 0.5056176961016088,
          0.0383219769668126,
        ],
      },
      {
        id: 181,
        position: [-9.34483161839674, 0.20967145658130448, 337.1703636822507],
        quaternion: [
          -0.5905860809655185, 0.6415650879544094, -0.36963405067442917,
          0.3208940439924906,
        ],
      },
      {
        id: 299,
        position: [-7.891470620226869, -4.181321395810336, 350.0487031690403],
        quaternion: [
          -0.73070481878267, 0.4770028817016305, -0.27572193162000447,
          0.40313290002164226,
        ],
      },
      {
        id: 315,
        position: [7.61989666742366, -4.244838473814212, 352.2192146933325],
        quaternion: [
          -0.7777868362249433, -0.38393891915571804, 0.2267709522498661,
          0.44295990672793567,
        ],
      },
      {
        id: 227,
        position: [-8.138166962632965, -4.407073606716395, 341.9418116520526],
        quaternion: [
          -0.7284721105401877, 0.4817870731075805, -0.27694104202372927,
          0.40064106079428086,
        ],
      },
      {
        id: 65,
        position: [-4.374452829700033, 7.422538622033031, 324.053116847252],
        quaternion: [
          -0.1939110257484773, 0.8437821120416152, -0.4910030651978464,
          0.09667601283712522,
        ],
      },
      {
        id: 322,
        position: [-6.30526813735645, -5.889944877252859, 353.0854914249136],
        quaternion: [
          -0.7863710369653375, 0.37979401785316774, -0.21837801026540454,
          0.43553202047327194,
        ],
      },
      {
        id: 199,
        position: [-0.4710660541532903, -9.03253630017673, 339.25215150349214],
        quaternion: [
          -0.8706543113038108, 0.05340601909540566, -0.027932009987133855,
          0.48818917455280286,
        ],
      },
      {
        id: 186,
        position: [-2.384409621691148, 8.772064600707381, 337.478807780164],
        quaternion: [
          -0.07760098134321312, 0.8600567932256007, -0.5033048789957072,
          0.031057992533053864,
        ],
      },
      {
        id: 159,
        position: [-7.937888033408342, 4.6553173456391335, 334.60620943387084],
        quaternion: [
          -0.4032209583388095, 0.7692289205225029, -0.44744795376923235,
          0.21328397796328727,
        ],
      },
      {
        id: 128,
        position: [-2.7135321401889243, -8.768220863048583, 331.26985895553014],
        quaternion: [
          -0.8582929300306757, 0.15843198708438727, -0.08584099300211377,
          0.4804829608303099,
        ],
      },
      {
        id: 221,
        position: [4.295102825694215, -7.878091090410436, 341.53638330331444],
        quaternion: [
          -0.853525288018743, -0.1676710565799369, 0.10051103391705209,
          0.4829891629827886,
        ],
      },
      {
        id: 88,
        position: [-6.180440639760789, 6.25807715975499, 326.58971914194603],
        quaternion: [
          -0.3032559593835675, 0.8136178910285019, -0.4701279370336541,
          0.15824497880553934,
        ],
      },
      {
        id: 139,
        position: [-0.030530951710669996, 8.91888524477082, 332.18998406867655],
        quaternion: [
          -0.03448899816175369, -0.8612089540979946, 0.5061289730235796,
          0.03103399834590345,
        ],
      },
      {
        id: 259,
        position: [-0.0790511791402757, 8.902204872375137, 345.3008950540073],
        quaternion: [
          -0.04378598099947174, -0.8599776268205296, 0.5069587800098478,
          0.03891098311493274,
        ],
      },
      {
        id: 226,
        position: [-6.5380889685006025, -6.254238751080408, 341.9637980904182],
        quaternion: [
          -0.7843931202039408, 0.3835580587781675, -0.2198030336836086,
          0.4350830666740922,
        ],
      },
      {
        id: 157,
        position: [-9.247570624630157, 0.14345437335918376, 334.45985645559864],
        quaternion: [
          -0.5897207968839753, 0.6420927788456275, -0.37057787236281187,
          0.32034088966580726,
        ],
      },
      {
        id: 51,
        position: [7.008811413757897, -4.476240890502609, 322.4122414387308],
        quaternion: [
          -0.7695177300497649, -0.40188385901735857, 0.23752691667450337,
          0.43578984712298746,
        ],
      },
      {
        id: 276,
        position: [-8.910826856674277, -2.3986074314487134, 347.47146616208954],
        quaternion: [
          -0.6640891503574378, 0.5660941281702352, -0.32740207412760314,
          0.3623960820506498,
        ],
      },
      {
        id: 300,
        position: [-8.689506955894672, -1.9390229086209594, 350.11433362117015],
        quaternion: [
          -0.6613724291815736, 0.5682203687328065, -0.32897821348242096,
          0.3626092353064557,
        ],
      },
      {
        id: 178,
        position: [-6.743185189720634, -6.324413779842217, 336.8928156432439],
        quaternion: [
          -0.7898682391681897, 0.37384411319814537, -0.21236106430187818,
          0.43732313241927795,
        ],
      },
      {
        id: 97,
        position: [8.683020221612594, -0.31476603385535995, 327.4914555744159],
        quaternion: [
          -0.6409240419606576, -0.5811410380467239, 0.34031802228028135,
          0.36834102411491926,
        ],
      },
      {
        id: 235,
        position: [-0.05762593713764019, 8.97447549794282, 342.7108255487975],
        quaternion: [
          -0.04577698339602737, -0.8601876879975094, 0.5064398163069687,
          0.0387339859506242,
        ],
      },
      {
        id: 335,
        position: [7.55688505925599, 4.316807437101012, 354.09747590236196],
        quaternion: [
          -0.46961114321122727, -0.7229552204702888, 0.4246541295012691,
          0.2765320843304077,
        ],
      },
      {
        id: 50,
        position: [7.864468299503557, -2.4102782831158223, 322.29454216306453],
        quaternion: [
          -0.7100110514988784, -0.49786703611154215, 0.29270502123062775,
          0.40291002922407276,
        ],
      },
      {
        id: 257,
        position: [-4.606414353580504, 7.85620346207773, 345.0209496154006],
        quaternion: [
          -0.1785539643137619, 0.8452778310606764, -0.49600090086802995,
          0.08721798256839772,
        ],
      },
      {
        id: 231,
        position: [-7.919243824700146, 4.749868050570029, 342.0195928450938],
        quaternion: [
          -0.3945151613324713, 0.7733513162531919, -0.4510411844481431,
          0.20699608464868566,
        ],
      },
      {
        id: 116,
        position: [2.237245416404086, 8.469013308237948, 329.6412908431206],
        quaternion: [
          -0.15095902388911647, -0.8483841342559515, 0.4980510788160914,
          0.09695801534351017,
        ],
      },
      {
        id: 172,
        position: [6.024335593945068, -6.523297345695525, 335.9984745193673],
        quaternion: [
          -0.8206802967457387, -0.2844901028673725, 0.16932706122613653,
          0.46570116839058745,
        ],
      },
      {
        id: 171,
        position: [7.484515988959885, -4.6349491111734125, 335.88240212129693],
        quaternion: [
          -0.7750699504521049, -0.38880797514467336, 0.22956098532485533,
          0.4420369717419034,
        ],
      },
      {
        id: 147,
        position: [7.542923575514152, -4.593771802497727, 333.1911708884181],
        quaternion: [
          -0.772047007899971, -0.3941010040326385, 0.23573000241210723,
          0.4393850044960071,
        ],
      },
      {
        id: 242,
        position: [8.563238344084368, -2.466119993760252, 343.3719184699636],
        quaternion: [
          -0.7210922331951366, -0.48030815532759996, 0.2835400916944704,
          0.4110171329194687,
        ],
      },
      {
        id: 110,
        position: [-8.764637694529341, 2.481007116123493, 329.19283574111535],
        quaternion: [
          -0.5010489302933262, 0.7108089011112064, -0.41350494247257613,
          0.2696559624850607,
        ],
      },
      {
        id: 206,
        position: [-9.085158572569725, 2.6333293458150493, 339.8151868494941],
        quaternion: [
          -0.49940614374958453, 0.7118872049103545, -0.41468811936426014,
          0.2680370771520715,
        ],
      },
      {
        id: 109,
        position: [-9.119282088926667, 0.15085846375455247, 329.1094572563651],
        quaternion: [
          -0.5902329394905026, 0.6407489343117041, -0.37178896188494115,
          0.3206849671240202,
        ],
      },
      {
        id: 287,
        position: [7.533902663776445, 4.412439235750746, 348.35147132362965],
        quaternion: [
          -0.47690393391399577, -0.7190189003634009, 0.42177494155338513,
          0.2787119613780501,
        ],
      },
      {
        id: 30,
        position: [1.7746244267975477, -7.930116084039753, 320.2551587714827],
        quaternion: [
          -0.867527114474548, -0.08475201118345237, 0.05653900746060522,
          0.4868440642415127,
        ],
      },
      {
        id: 212,
        position: [2.3380915673268987, 8.763598848968513, 340.4283457806847],
        quaternion: [
          -0.1565229178253332, -0.847345555142853, 0.4971937389728584,
          0.10151094670666547,
        ],
      },
      {
        id: 179,
        position: [-8.177081024438024, -4.3636731984711545, 336.9847244249364],
        quaternion: [
          -0.7351777261445102, 0.47216782411633784, -0.26977089950968425,
          0.4047158492423625,
        ],
      },
      {
        id: 189,
        position: [4.438863417020869, 7.75997720704747, 337.69602809816337],
        quaternion: [
          -0.26421196712767386, -0.8202068979527349, 0.4815719400845085,
          0.15981598011625636,
        ],
      },
      {
        id: 59,
        position: [
          -7.7370753908527945, -4.1845822454386195, 323.57738479729585,
        ],
        quaternion: [
          -0.7385531559340678, 0.46609209840813254, -0.26584305612864667,
          0.4081970861844968,
        ],
      },
      {
        id: 316,
        position: [6.1336138597716285, -6.127359833207947, 352.50283215323014],
        quaternion: [
          -0.8226827335203543, -0.2784329098112794, 0.16635694611441534,
          0.46689784876457435,
        ],
      },
      {
        id: 100,
        position: [5.921081133574006, -6.503628596217624, 327.90217198535305],
        quaternion: [
          -0.8187644573222436, -0.28865716122993546, 0.17203609609104636,
          0.46551626001487795,
        ],
      },
      {
        id: 60,
        position: [-8.25604458953336, -2.073701880193216, 323.57820777243],
        quaternion: [
          -0.6699664185288223, 0.559363349434953, -0.3205882002718319,
          0.36807722993828546,
        ],
      },
      {
        id: 317,
        position: [4.217950730122524, -7.4935647895139255, 352.6977176160611],
        quaternion: [
          -0.8532745632135559, -0.16881991358203688, 0.10294194730459684,
          0.48251975300085553,
        ],
      },
      {
        id: 224,
        position: [-2.537467904255351, -8.673495900701084, 342.1128056001673],
        quaternion: [
          -0.8553428395175978, 0.1708229679495999, -0.09568498204725047,
          0.47963791000866507,
        ],
      },
      {
        id: 136,
        position: [-6.357814642165579, 6.391026830982867, 332.03325475143777],
        quaternion: [
          -0.29445190270707117, 0.8142057309697798, -0.476225842645245,
          0.1535449492656095,
        ],
      },
      {
        id: 102,
        position: [1.9988710550824322, -8.615522626430305, 328.24657671156075],
        quaternion: [
          -0.8681753130888173, -0.06530302355013567, 0.0447220161280365,
          0.48990517667380085,
        ],
      },
      {
        id: 223,
        position: [
          -0.20727096722232474, -9.016849340958856, 342.02340509145336,
        ],
        quaternion: [
          -0.8695669252372478, 0.058239994992700175, -0.030552997373145062,
          0.48941595792148607,
        ],
      },
      {
        id: 254,
        position: [-8.89033553690345, 2.4894007553330795, 344.646712844934],
        quaternion: [
          -0.49086902866233423, 0.7178290419147568, -0.418304024425191,
          0.2622800153147928,
        ],
      },
      {
        id: 17,
        position: [-4.131859543047597, 6.761455571909707, 318.76545363000145],
        quaternion: [
          -0.20678101373367166, 0.8417420559055632, -0.48710103235154684,
          0.10697900710517148,
        ],
      },
      {
        id: 130,
        position: [-6.632920811199483, -6.353702425823897, 331.5522784640446],
        quaternion: [
          -0.7895926521580621, 0.3736088354128284, -0.20977890758538398,
          0.43926380648962055,
        ],
      },
      {
        id: 346,
        position: [-6.1242292851763835, -5.769606671634975, 355.6668946164483],
        quaternion: [
          -0.7882758112103306, 0.37557591005070706, -0.21480094855582338,
          0.4375208952150707,
        ],
      },
      {
        id: 371,
        position: [-7.204841501794448, -3.7969126050497977, 358.51499752598227],
        quaternion: [
          -0.7332479282850566, 0.4730279537357398, -0.2714849734475492,
          0.4060639602851193,
        ],
      },
      {
        id: 190,
        position: [6.320494574751706, 6.3049416138999135, 337.74891124686616],
        quaternion: [
          -0.3699722091965902, -0.7788454403893221, 0.4567332582546415,
          0.21889512377176545,
        ],
      },
      {
        id: 151,
        position: [-0.4502145593201359, -9.066953777077373, 333.8129718895379],
        quaternion: [
          -0.8706906419921076, 0.04373998201512912, -0.020507991567587286,
          0.489452798748308,
        ],
      },
      {
        id: 275,
        position: [-7.979605472283055, -4.530346540848875, 347.4832266874961],
        quaternion: [
          -0.7309690556285747, 0.4772550363203028, -0.2749200209221017,
          0.4029030306619291,
        ],
      },
      {
        id: 49,
        position: [8.047778494910716, -0.1914549968122241, 322.1308982023113],
        quaternion: [
          -0.6366048166237809, -0.5872988308265407, 0.34438390079902637,
          0.3622348956569855,
        ],
      },
      {
        id: 219,
        position: [7.747258887754597, -4.67379184056098, 340.95970702155336],
        quaternion: [
          -0.779391938686018, -0.38097497002908126, 0.22484298231182812,
          0.4436799650961422,
        ],
      },
      {
        id: 145,
        position: [8.801410396034385, -0.1485239163323726, 332.8640558319187],
        quaternion: [
          -0.6413863642844685, -0.5784363285311042, 0.34407619542294776,
          0.3683022091824553,
        ],
      },
    ],
    meshData: [
      {
        id: 1,
        position: [-0.52, 0.3, -350.8],
        rotation: [-1.559, -0.488, 0.005],
        size: [0.4, 2.6, 0.3],
        description: 'Antenna Object 1',
      },
      {
        id: 2,
        position: [-0.52, 0.5, -348.6],
        rotation: [-1.559, -0.029, 0.005],
        size: [0.9, 1.9, 0.3],
        description: 'Antenna Object 2',
      },
      {
        id: 3,
        position: [-0.52, 1.5, -346.1],
        rotation: [-1.559, -0.896, 0.005],
        size: [0.6, 2.4, 0.3],
        description: 'Antenna Object 3',
      },
      {
        id: 4,
        position: [-1.32, 1.05, -342.8],
        rotation: [-1.559, -1.474, 0.039],
        size: [0.6, 1.8, 0.3],
        description: 'Antenna Object 4',
      },
      {
        id: 5,
        position: [-1.52, 0.15, -342.8],
        rotation: [-1.559, -1.457, 0.039],
        size: [0.5, 0.9, 0.4],
        description: 'Antenna Object 5',
      },
      {
        id: 6,
        position: [-1.52, -0.05, -347.3],
        rotation: [-1.559, -1.78, 0.039],
        size: [0.6, 2.2, 0.4],
        description: 'Antenna Object 6',
      },
      {
        id: 7,
        position: [-1.22, -1.75, -346.1],
        rotation: [-1.644, -3, 0.039],
        size: [0.6, 2.2, 0.4],
        description: 'Antenna Object 7',
      },
      {
        id: 8,
        position: [-0.82, -1.95, -342.7],
        rotation: [-1.576, -2.885, 0.039],
        size: [0.6, 1.8, 0.4],
        description: 'Antenna Object 8',
      },
      {
        id: 9,
        position: [-0.02, -2.35, -342.9],
        rotation: [-1.576, -2.885, 0.039],
        size: [0.6, 0.9, 0.4],
        description: 'Antenna Object 9',
      },
      {
        id: 10,
        position: [0.08, -2.35, -339.5],
        rotation: [-1.576, -2.885, 0.039],
        size: [0.6, 1.5, 0.4],
        description: 'Antenna Object 10',
      },
      {
        id: 11,
        position: [-0.82, -1.95, -339.4],
        rotation: [-1.576, -2.868, 0.039],
        size: [0.6, 1.6, 0.4],
        description: 'Antenna Object 11',
      },
      {
        id: 12,
        position: [-0.22, -1.45, -348.6],
        rotation: [-1.576, -2.188, 0.056],
        size: [0.6, 1.5, 0.3],
        description: 'Antenna Object 12',
      },
      {
        id: 13,
        position: [-0.22, -1.45, -351],
        rotation: [-1.474, -2.086, 0.056],
        size: [0.5, 2.4, 0.3],
        description: 'Antenna Object 13',
      },
      {
        id: 14,
        position: [-0.72, -0.85, -351.2],
        rotation: [-2.052, -1.831, -0.437],
        size: [0.5, 1, 0.3],
        description: 'Antenna Object 14',
      },
      {
        id: 15,
        position: [0.58, -2.05, -347.3],
        rotation: [-1.627, 2.317, 0.022],
        size: [0.7, 2.2, 0.3],
        description: 'Antenna Object 15',
      },
      {
        id: 16,
        position: [1.38, -0.35, -348.7],
        rotation: [-1.627, 2.13, 0.022],
        size: [0.9, 2, 0.3],
        description: 'Antenna Object 16',
      },
      {
        id: 17,
        position: [1.28, -0.35, -351],
        rotation: [-1.627, 2.47, 0.022],
        size: [0.4, 2.2, 0.3],
        description: 'Antenna Object 17',
      },
      {
        id: 18,
        position: [2.08, -0.85, -346.2],
        rotation: [-1.406, 1.127, -0.199],
        size: [0.6, 2.2, 0.3],
        description: 'Antenna Object 18',
      },
      {
        id: 19,
        position: [-1.52, 0.2, -339.3],
        rotation: [-1.559, -1.457, 0.039],
        size: [0.5, 1.7, 0.4],
        description: 'Antenna Object 19',
      },
      {
        id: 20,
        position: [-1.42, 1.1, -339.3],
        rotation: [-1.559, -1.457, 0.039],
        size: [0.5, 1.7, 0.4],
        description: 'Antenna Object 20',
      },
      {
        id: 21,
        position: [1.38, 0.75, -347.3],
        rotation: [-1.542, 0.175, -0.046],
        size: [0.6, 2.3, 0.3],
        description: 'Antenna Object 21',
      },
      {
        id: 22,
        position: [1.98, 0.15, -342.6],
        rotation: [-1.576, 0.617, -0.063],
        size: [0.6, 2, 0.3],
        description: 'Antenna Object 22',
      },
      {
        id: 23,
        position: [1.38, 0.75, -342.9],
        rotation: [-1.542, 0.719, -0.182],
        size: [0.6, 1, 0.3],
        description: 'Antenna Object 23',
      },
      {
        id: 24,
        position: [1.98, 0.15, -339.4],
        rotation: [-1.576, 0.617, -0.063],
        size: [0.6, 1.6, 0.3],
        description: 'Antenna Object 24',
      },
      {
        id: 25,
        position: [1.38, 0.75, -339.4],
        rotation: [-1.576, 0.77, -0.063],
        size: [0.6, 1.6, 0.3],
        description: 'Antenna Object 25',
      },
      {
        id: 26,
        position: [0.98, -1.15, -351.2],
        rotation: [-1.627, 2.47, 0.022],
        size: [0.4, 1, 0.3],
        description: 'Antenna Object 26',
      },
    ],
  },
];
