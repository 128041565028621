export const TEST_MODELS = [
  {
    id: '101',
    name: 'st_comb/st_1',
    file: 'st_1.splat',
    viewSettings: {
      camera: {
        position: [-22.08, 9.11, -7.05],
        rotation: [-3.07, -50.37, -5.01],
        fov: 75,
        near: 0.1,
        far: 1000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 200,
      maxPanY: 200,
      maxPanZ: 200,
    },
  },
  {
    id: '102',
    name: 'st_comb/st_2',
    file: 'st_2.splat',
    viewSettings: {
      camera: {
        position: [-22.08, 9.11, -7.05],
        rotation: [-3.07, -50.37, -5.01],
        fov: 75,
        near: 0.05,
        far: 1000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 200,
      maxPanY: 200,
      maxPanZ: 200,
    },
  },
  { id: '103', name: 'RCH', file: 'rch.splat' },
  {
    id: '104',
    name: 'st_comb/st_1a',
    file: 'ST_1_antialiased.splat',
    viewSettings: {
      camera: {
        position: [-24.24, -8.12, -7.82],
        rotation: [-92.05, -47.13, -4.72],
        fov: 75,
        near: 0.1,
        far: 1000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 200,
      maxPanY: 200,
      maxPanZ: 200,
    },
  },
  {
    id: '105',
    name: 'st_comb/st_2a',
    file: 'ST_2_antialiased.splat',
    viewSettings: {
      camera: {
        position: [-24.24, -8.12, -7.82],
        rotation: [-92.05, -47.13, -4.72],
        fov: 75,
        near: 0.05,
        far: 1000,
      },
      modelPosition: [0, 0, 0],
      maxPanX: 200,
      maxPanY: 200,
      maxPanZ: 200,
    },
  },
  {
    id: '106',
    name: 'tower/0607',
    file: 'point_cloud_0607.splat',
    viewSettings: {
      camera: {
        position: [0, 0, 162],
        rotation: [0, 0, 0],
        fov: 75,
        near: 0.1,
        far: 1000,
      },
    },
    cameraData: [
      {
        id: 257,
        position: [
          -1.0744728129533727, -0.18783209635097783, -1.9615802458554707,
        ],
        rotation: [
          0.014323226568438228, 0.2431066693710997, 0.11129853801078438,
        ],
      },
      {
        id: 256,
        position: [
          -0.5803247963693393, -0.08722642969151423, -2.137381015979411,
        ],
        rotation: [
          0.02807933586429483, 0.019660057121869245, -0.012333203813573373,
        ],
      },
      {
        id: 255,
        position: [
          -0.06698974416393544, -0.04387465077205209, -2.1792081388940336,
        ],
        rotation: [
          0.014195082409056432, -0.204712614961299, -0.13639423632988337,
        ],
      },
      {
        id: 254,
        position: [
          0.4281529271183423, -0.05589370869765742, -2.139231133550761,
        ],
        rotation: [
          -0.02930128661383205, -0.4240473592306164, -0.27258828104490873,
        ],
      },
      {
        id: 253,
        position: [
          0.9001340477717075, -0.1301318033011604, -1.9741692774128112,
        ],
        rotation: [
          -0.11628489538697856, -0.6367892121004689, -0.44114441577278046,
        ],
      },
      {
        id: 252,
        position: [
          1.3140649104582196, -0.2508800265602704, -1.7135574523247188,
        ],
        rotation: [
          -0.2763747190333789, -0.8342564054883027, -0.676379484488387,
        ],
      },
      {
        id: 251,
        position: [
          1.6399858256226711, -0.4132522831887571, -1.3414233190240112,
        ],
        rotation: [
          -0.5573470188257946, -0.9904311001100403, -1.027762615284976,
        ],
      },
      {
        id: 250,
        position: [
          1.8420809611759974, -0.6132986988194351, -0.9424368971164212,
        ],
        rotation: [
          -0.9741232871506427, -1.0615930492095114, -1.5114443879367065,
        ],
      },
      {
        id: 249,
        position: [
          1.9340927334428106, -0.8374901891682289, -0.47143551584613363,
        ],
        rotation: [-1.4191520828199222, -1.019119596291305, -2.023710196869362],
      },
      {
        id: 248,
        position: [
          1.8759784576639889, -1.0460357940326293, -0.018606345400711155,
        ],
        rotation: [
          -1.7353745890767964, -0.8845477431288842, -2.407908953740967,
        ],
      },
      {
        id: 247,
        position: [1.7139383819755432, -1.2579546405171846, 0.422462758721212],
        rotation: [
          -1.926928005747266, -0.6925332325980875, -2.6753355848040927,
        ],
      },
      {
        id: 246,
        position: [1.3831622093848481, -1.4183280849719107, 0.7890946510389885],
        rotation: [
          -2.0318601331209556, -0.48242593476064877, -2.8601681112775883,
        ],
      },
      {
        id: 245,
        position: [0.9773209308340294, -1.5481138257439555, 1.0833838281670247],
        rotation: [
          -2.0868012707320487, -0.2593707231463074, -3.0069566830223193,
        ],
      },
      {
        id: 244,
        position: [0.5270160739573517, -1.6173412959562652, 1.2798733478625552],
        rotation: [
          -2.1070991930483984, -0.03370891859903469, -3.1335015584408863,
        ],
      },
      {
        id: 243,
        position: [
          0.026556162576559683, -1.6223722081103655, 1.368154255480647,
        ],
        rotation: [-2.0986517004368253, 0.19394905882430202, 3.024940806486061],
      },
      {
        id: 242,
        position: [
          -0.4566356817014317, -1.5704945454488506, 1.3252916521190863,
        ],
        rotation: [-2.059775697214096, 0.4148832728331535, 2.8896802055985797],
      },
      {
        id: 241,
        position: [
          -0.9271707383118524, -1.4418632082282092, 1.1546081674192683,
        ],
        rotation: [-1.97470295291122, 0.6377897866423674, 2.717284652799386],
      },
      {
        id: 240,
        position: [
          -1.3178165202811731, -1.2792270028327224, 0.9181036023962024,
        ],
        rotation: [-1.8282474560289927, 0.8293340011820756, 2.497357824631882],
      },
      {
        id: 239,
        position: [
          -1.6754859436389027, -1.0745768436543954, 0.6007887651861674,
        ],
        rotation: [-1.5480908758721628, 0.9947067668264937, 2.143758994561401],
      },
      {
        id: 238,
        position: [
          -1.8797638922941458, -0.8353623311998989, 0.2300737565733903,
        ],
        rotation: [-1.1309402437630376, 1.072435701478002, 1.6588982215816719],
      },
      {
        id: 237,
        position: [
          -1.9564527939143086, -0.5649323842348575, -0.1918644333785443,
        ],
        rotation: [-0.6776536296701392, 1.0369400920188245, 1.1384611581362696],
      },
      {
        id: 236,
        position: [
          -1.899756108148389, -0.3076034484175636, -0.6286735023360122,
        ],
        rotation: [
          -0.34080134550457925, 0.9000125836689978, 0.7296222261485159,
        ],
      },
      {
        id: 235,
        position: [
          -1.7103562467873985, -0.05400751942381282, -1.0527601010963943,
        ],
        rotation: [
          -0.14783292411948196, 0.7098232354756271, 0.4602595963815746,
        ],
      },
      {
        id: 234,
        position: [
          -1.3898382955831652, 0.16090385446103558, -1.4093314901861245,
        ],
        rotation: [
          -0.044858158759097905, 0.5012653158884208, 0.2762174199074441,
        ],
      },
      {
        id: 233,
        position: [
          -1.0372935160345973, 0.3370452947907099, -1.6701976302480783,
        ],
        rotation: [
          0.009122209493077092, 0.2780465232337934, 0.12950263809335297,
        ],
      },
      {
        id: 232,
        position: [-0.549891126576644, 0.4508442074006075, -1.844961233912221],
        rotation: [
          0.027799869575538358, 0.04391417828309496, -0.002443454797740059,
        ],
      },
      {
        id: 231,
        position: [
          -0.06170418492798019, 0.5030206084061475, -1.9049915692552846,
        ],
        rotation: [
          0.017745016489683928, -0.1853049237043043, -0.1289427128044889,
        ],
      },
      {
        id: 230,
        position: [
          0.43313984639113623, 0.4887295271978073, -1.8492118531639972,
        ],
        rotation: [
          -0.02326006075573782, -0.4065403543080912, -0.26331021974029145,
        ],
      },
      {
        id: 229,
        position: [0.9154176457427706, 0.4163872343232103, -1.6743405698353462],
        rotation: [
          -0.10872413462972458, -0.6253772105984955, -0.4321617166124902,
        ],
      },
      {
        id: 228,
        position: [1.3286063736528377, 0.2927412493176422, -1.4055649738904468],
        rotation: [
          -0.2641570602025737, -0.8248155396276147, -0.6624040791936144,
        ],
      },
      {
        id: 227,
        position: [1.6580771712345466, 0.11584691922041535, -1.060180093969629],
        rotation: [
          -0.5331679100490305, -0.9838877827894347, -1.0014314809537217,
        ],
      },
      {
        id: 226,
        position: [
          1.8563452693644074, -0.08667339819345177, -0.6282024057595803,
        ],
        rotation: [-0.946859998184318, -1.0622489243618238, -1.48161388623211],
      },
      {
        id: 225,
        position: [1.950075690663626, -0.3043358934704714, -0.1877548830469078],
        rotation: [
          -1.4111751965004957, -1.023066562618006, -2.0158953982433125,
        ],
      },
      {
        id: 224,
        position: [1.891389145976047, -0.5024973687330103, 0.25914840206074036],
        rotation: [
          -1.724272771532637, -0.8948571525012512, -2.3942676397055287,
        ],
      },
      {
        id: 223,
        position: [1.6911873432208573, -0.7084129098065299, 0.7126675440730172],
        rotation: [
          -1.9144274650982052, -0.7133118430916244, -2.6558443159064646,
        ],
      },
      {
        id: 222,
        position: [1.3817788586355915, -0.8975176668014117, 1.0929216151050973],
        rotation: [
          -2.027008013255877, -0.49817579263890077, -2.8490147712547897,
        ],
      },
      {
        id: 221,
        position: [0.9881810813617585, -1.0315607791287755, 1.3942714796702518],
        rotation: [
          -2.0849044556964436, -0.27618420318034276, -2.995949076109302,
        ],
      },
      {
        id: 220,
        position: [0.5306205601745513, -1.093845697859909, 1.5842975164633606],
        rotation: [
          -2.108175768136927, -0.04707236713463003, -3.1256302086835253,
        ],
      },
      {
        id: 219,
        position: [
          0.039199234507778846, -1.092461664820962, 1.6662438403116404,
        ],
        rotation: [-2.1021885135258005, 0.18118558957578368, 3.032900796758992],
      },
      {
        id: 218,
        position: [
          -0.4528355069779182, -1.0463003475816337, 1.6287268592970476,
        ],
        rotation: [-2.0654969237618754, 0.4024512747142097, 2.8993789707622524],
      },
      {
        id: 217,
        position: [
          -0.9291882256095076, -0.9130157791603803, 1.4502249410364687,
        ],
        rotation: [-1.9866550335691273, 0.6236438038908569, 2.7338730471358077],
      },
      {
        id: 216,
        position: [-1.327590056266331, -0.7499817118397801, 1.2233051637821282],
        rotation: [-1.8382918738083296, 0.8260830077950287, 2.508103085087944],
      },
      {
        id: 215,
        position: [
          -1.6426007015668644, -0.5463056515291556, 0.9169035115065043,
        ],
        rotation: [-1.5768094622723967, 0.9886245251071766, 2.1761300360742566],
      },
      {
        id: 214,
        position: [
          -1.8622240675169008, -0.28621511225010277, 0.4886118575158574,
        ],
        rotation: [-1.1610618189490114, 1.0737518163499429, 1.6914238359717437],
      },
      {
        id: 213,
        position: [
          -1.9447142750660609, -0.02586009383494292, 0.07985994957150544,
        ],
        rotation: [-0.6938306283010578, 1.0422672348535311, 1.154603596454911],
      },
      {
        id: 212,
        position: [
          -1.8972900769841856, 0.24627843882506406, -0.3690995084786116,
        ],
        rotation: [-0.350636892896188, 0.9071822714963087, 0.7386190057511608],
      },
      {
        id: 211,
        position: [
          -1.720195682409352, 0.48336392616319557, -0.7484029243721976,
        ],
        rotation: [
          -0.15782136399590518, 0.7237944962514993, 0.4726527385721794,
        ],
      },
      {
        id: 210,
        position: [
          -1.4027961068473105, 0.6942050650148738, -1.0976257758220485,
        ],
        rotation: [
          -0.048244687084297105, 0.5108482289273741, 0.2818070830099575,
        ],
      },
      {
        id: 209,
        position: [-1.001521325170861, 0.8744597337543287, -1.3760154611383084],
        rotation: [
          0.008499411537896809, 0.28729181212299837, 0.1341760193111866,
        ],
      },
      {
        id: 208,
        position: [-0.5212433968810388, 0.983762954906175, -1.5480625727270365],
        rotation: [
          0.028729533370345298, 0.06208634963381998, 0.006504847697275468,
        ],
      },
      {
        id: 207,
        position: [
          -0.044608843325417424, 1.0443838554570264, -1.6182892354705352,
        ],
        rotation: [
          0.020560323060693615, -0.16648830857493535, -0.11865992536886816,
        ],
      },
      {
        id: 206,
        position: [0.45173381381698674, 1.033034368964312, -1.5578213952804454],
        rotation: [
          -0.020768639678837783, -0.39831622248836784, -0.25840884806874875,
        ],
      },
      {
        id: 205,
        position: [0.9176067128515749, 0.9522509520961013, -1.3658767167090353],
        rotation: [
          -0.10348392576884688, -0.6158442861946444, -0.42337463411532994,
        ],
      },
      {
        id: 204,
        position: [1.3172442968635896, 0.8313525704845233, -1.0981454784649278],
        rotation: [
          -0.25289685645915805, -0.814021756856268, -0.6468593191036482,
        ],
      },
      {
        id: 203,
        position: [1.649554560619416, 0.6654777643875687, -0.7636339570452866],
        rotation: [
          -0.5102527869304344, -0.9740877177891286, -0.9734283101798861,
        ],
      },
      {
        id: 202,
        position: [
          1.8686074685800542, 0.4659093367973546, -0.35206806827359893,
        ],
        rotation: [
          -0.9193207292886911, -1.0605369416645076, -1.4513107616003014,
        ],
      },
      {
        id: 201,
        position: [1.940574460134608, 0.2372247078943146, 0.12900950050634716],
        rotation: [
          -1.370302771558709, -1.0321419589706866, -1.9676575893585841,
        ],
      },
      {
        id: 200,
        position: [
          1.8949467062314684, 0.021209868648581542, 0.5855819425941349,
        ],
        rotation: [
          -1.7084930616640155, -0.9039007589169298, -2.374657937912913,
        ],
      },
      {
        id: 199,
        position: [1.7049955127830179, -0.1845476545546174, 0.9990566045200284],
        rotation: [-1.9089363447126955, -0.72049397151256, -2.647845275411683],
      },
      {
        id: 198,
        position: [
          1.4064267086321627, -0.34239623195520497, 1.3557678922499963,
        ],
        rotation: [
          -2.0216682837750817, -0.5112137335181064, -2.839704551139258,
        ],
      },
      {
        id: 197,
        position: [1.02516501176255, -0.46793954454587927, 1.6699129323894648],
        rotation: [-2.081535898682763, -0.2902815836937387, -2.987541548142174],
      },
      {
        id: 196,
        position: [0.5508570381016318, -0.5339302197685045, 1.855873396719689],
        rotation: [
          -2.106464498974982, -0.05941917536374519, -3.118942825121871,
        ],
      },
      {
        id: 195,
        position: [
          0.05452861469022613, -0.5408278783892216, 1.9314153849075182,
        ],
        rotation: [-2.101635512112477, 0.16757898573532026, 3.039810258770026],
      },
      {
        id: 194,
        position: [
          -0.4337035436965784, -0.48518994354395517, 1.9034332140538681,
        ],
        rotation: [
          -2.0663918148207223, 0.39268664485613347, 2.9057758617027645,
        ],
      },
      {
        id: 193,
        position: [
          -0.9012469681655395, -0.3867297644079325, 1.7774346676115635,
        ],
        rotation: [-1.9895744968565083, 0.60764694380169, 2.745029986292434],
      },
      {
        id: 192,
        position: [
          -1.3247989032390564, -0.19471395073145592, 1.4993194446827967,
        ],
        rotation: [-1.841351730121664, 0.8167007625318199, 2.517020935113094],
      },
      {
        id: 191,
        position: [
          -1.6483712845059082, 0.03046270213323532, 1.1590657054618991,
        ],
        rotation: [-1.5862205509179599, 0.9795135484257056, 2.1918287959485294],
      },
      {
        id: 190,
        position: [
          -1.8456401882786886, 0.27054564687376237, 0.7641090230782467,
        ],
        rotation: [-1.1847989965601036, 1.0689419433355383, 1.7227552445426815],
      },
      {
        id: 189,
        position: [-1.9180793611336524, 0.5288936660811672, 0.3481614714054291],
        rotation: [-0.7261015426444466, 1.0474475741409268, 1.1967177905354063],
      },
      {
        id: 188,
        position: [
          -1.8718925940026454, 0.7881265140692726, -0.07013583222818853,
        ],
        rotation: [
          -0.37404327262944986, 0.9214698046710419, 0.7732971599373684,
        ],
      },
      {
        id: 187,
        position: [
          -1.6915461890504067, 1.0398153792374638, -0.4805956664993486,
        ],
        rotation: [-0.1667767779179616, 0.7365670572017908, 0.4900852161175494],
      },
      {
        id: 186,
        position: [
          -1.4247914141449318, 1.2562756251676288, -0.8186668257889137,
        ],
        rotation: [
          -0.05138201420580278, 0.5208196201227753, 0.29139095615390975,
        ],
      },
      {
        id: 185,
        position: [-1.0358991636484958, 1.4236692782273728, -1.080700269386275],
        rotation: [
          0.005358120989054235, 0.30390303639051797, 0.1446491135477742,
        ],
      },
      {
        id: 184,
        position: [
          -0.5573250791855577, 1.5217079397106377, -1.2335923723226438,
        ],
        rotation: [
          0.027864750967450086, 0.07809952856685842, 0.015343311036820921,
        ],
      },
      {
        id: 183,
        position: [
          -0.07777815468822138, 1.5653539629588225, -1.2649042646894237,
        ],
        rotation: [
          0.02263148652585909, -0.14209334345347568, -0.10537493736798151,
        ],
      },
      {
        id: 182,
        position: [0.4522648692621647, 1.563596068950005, -1.2453501194741432],
        rotation: [
          -0.013785395792258376, -0.37388728037293206, -0.24332834343578078,
        ],
      },
      {
        id: 181,
        position: [0.9054196899516135, 1.502156079520857, -1.0826827841953448],
        rotation: [
          -0.08957023914156972, -0.5885080697064156, -0.4013876890154401,
        ],
      },
      {
        id: 180,
        position: [1.3350018258180827, 1.3655085497672377, -0.8243789928366447],
        rotation: [
          -0.22857592039452682, -0.7915377764343767, -0.6162290462362101,
        ],
      },
      {
        id: 179,
        position: [1.6460811353553244, 1.1937704216072207, -0.4400136692412835],
        rotation: [
          -0.47434058906183335, -0.958425967838053, -0.9310756522868926,
        ],
      },
      {
        id: 178,
        position: [
          1.8421967701989206, 1.0114843674093712, -0.06121616441877947,
        ],
        rotation: [-0.8672126692568711, -1.055973760006765, -1.392849078848378],
      },
      {
        id: 177,
        position: [1.9210323552637045, 0.7867191604366085, 0.3965179998107439],
        rotation: [
          -1.3215288756757968, -1.041401948728716, -1.9127354753011439,
        ],
      },
      {
        id: 176,
        position: [1.8809291349241222, 0.5545253343428103, 0.865650764062873],
        rotation: [
          -1.6798569981515463, -0.9203992965069079, -2.3399373433586153,
        ],
      },
      {
        id: 175,
        position: [1.6910623205588202, 0.35993996765603575, 1.2945941536632166],
        rotation: [-1.893149567273623, -0.7399974382087267, -2.625607256227843],
      },
      {
        id: 174,
        position: [1.4032348473331555, 0.1894958756018571, 1.668201000983775],
        rotation: [
          -2.014130214911117, -0.5287188114473829, -2.8258572634743206,
        ],
      },
      {
        id: 173,
        position: [0.9883518960030315, 0.07828842303784156, 1.949004691632783],
        rotation: [
          -2.0778317628072003, -0.30596588648621825, -2.978309180836587,
        ],
      },
      {
        id: 172,
        position: [0.5430823371901367, 0.0172067973163742, 2.145708936768834],
        rotation: [
          -2.104366850059242, -0.07676129791849884, -3.1101002634786155,
        ],
      },
      {
        id: 171,
        position: [
          0.05972429900638679, 0.0037812571201645273, 2.215600350529755,
        ],
        rotation: [-2.101628861326475, 0.14913170922235097, 3.0482713847053002],
      },
      {
        id: 170,
        position: [
          -0.4341550179860351, 0.05165484205341808, 2.1881779594324917,
        ],
        rotation: [-2.068543453001457, 0.3734463088625355, 2.914853119146523],
      },
      {
        id: 169,
        position: [
          -0.9027537869968474, 0.16422515229623313, 2.0485374713466036,
        ],
        rotation: [-1.9970761483939523, 0.589909385751707, 2.757653968188639],
      },
      {
        id: 168,
        position: [-1.310829899324669, 0.35597390716797717, 1.7574019821101987],
        rotation: [-1.8637918505271678, 0.7956943846508193, 2.5456420960154604],
      },
      {
        id: 167,
        position: [-1.6165488767402851, 0.5279091521934152, 1.497174086678775],
        rotation: [-1.6280057475684377, 0.9653507459628679, 2.239704290804221],
      },
      {
        id: 166,
        position: [-1.8359228583521072, 0.7868368647946686, 1.0807080689655357],
        rotation: [-1.2378180917802974, 1.0677424037243162, 1.7813367724589888],
      },
      {
        id: 165,
        position: [-1.9203266716766125, 1.0475715189863082, 0.6692449254347457],
        rotation: [-0.7660034159630476, 1.0580928766110613, 1.2417578544559364],
      },
      {
        id: 164,
        position: [
          -1.8674105232217506, 1.3227842776349092, 0.21595713982891918,
        ],
        rotation: [
          -0.39165279627156324, 0.9356443949505353, 0.7958682273203919,
        ],
      },
      {
        id: 163,
        position: [
          -1.6899735917487235, 1.5590823985348614, -0.1634391414420786,
        ],
        rotation: [-0.1803186972229524, 0.7586549512721898, 0.5114665818426769],
      },
      {
        id: 162,
        position: [
          -1.3913350152718977, 1.7705948780701928, -0.5088790417381353,
        ],
        rotation: [
          -0.05758131309666112, 0.5428898053214848, 0.3055354498866715,
        ],
      },
      {
        id: 161,
        position: [-1.012864300140321, 1.933848935852869, -0.7656098681554648],
        rotation: [
          0.003824214141326901, 0.32306490407799515, 0.15467217779623038,
        ],
      },
      {
        id: 160,
        position: [
          -0.5405970488998225, 2.0561156625694914, -0.9453444828843147,
        ],
        rotation: [
          0.028852428583587778, 0.09385648750553144, 0.023910248987946334,
        ],
      },
      {
        id: 159,
        position: [
          -0.047841876996922314, 2.1043593079358285, -0.9836279916824664,
        ],
        rotation: [
          0.02363789912748679, -0.12987149848017965, -0.09805230499234371,
        ],
      },
      {
        id: 158,
        position: [0.45771387235550987, 2.094180048365941, -0.9243336742709878],
        rotation: [
          -0.009119090676711239, -0.3545832665444595, -0.22917449731965567,
        ],
      },
      {
        id: 157,
        position: [0.9206848913257349, 2.0217012123662452, -0.7605518656978782],
        rotation: [
          -0.08422806640779729, -0.5792095281307441, -0.3906893450368247,
        ],
      },
      {
        id: 156,
        position: [1.318737945057936, 1.8933339615560636, -0.4756134858583013],
        rotation: [
          -0.2161055060021108, -0.7787174564150786, -0.5967946927093206,
        ],
      },
      {
        id: 155,
        position: [1.6327429993155205, 1.741875037614042, -0.15279649228458742],
        rotation: [
          -0.45299002987459447, -0.9515505556257383, -0.9060483513643945,
        ],
      },
      {
        id: 154,
        position: [1.833922034024649, 1.5298296413600163, 0.2607381614807127],
        rotation: [
          -0.8377759390078483, -1.0549758014792676, -1.3595241666565012,
        ],
      },
      {
        id: 153,
        position: [1.9134459512334263, 1.3268872617846172, 0.6956664357663003],
        rotation: [-1.297264201355286, -1.0471516392495668, -1.885282867061786],
      },
      {
        id: 152,
        position: [1.8794382926204514, 1.1070806653531924, 1.146667539996641],
        rotation: [
          -1.6670974595603825, -0.9299432866628211, -2.323914527505594,
        ],
      },
      {
        id: 151,
        position: [1.6950349684163228, 0.8925901171060944, 1.5918288654045032],
        rotation: [-1.8839592169275496, -0.753438423743755, -2.611098512016677],
      },
      {
        id: 150,
        position: [1.397154384887337, 0.7355008594496724, 1.9638043782791392],
        rotation: [
          -2.0076843113877962, -0.5481015509063871, -2.812357984258226,
        ],
      },
      {
        id: 149,
        position: [1.0039051191118158, 0.6173583470817915, 2.245576263408072],
        rotation: [-2.075159329655754, -0.32499976134245445, -2.96753288508784],
      },
      {
        id: 148,
        position: [0.5658294934095941, 0.5700108946806828, 2.4116631780587108],
        rotation: [
          -2.1060194920151907, -0.09858473120018152, -3.0987069815345047,
        ],
      },
    ],
  },
];
